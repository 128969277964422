import apiService from "./apiServices";

const saveProposal = async (gigId, applicationId, data) => {
  const request = {
    method: 'post',
    url: `/gig/proposal/${gigId}/${applicationId}/new`,
    data
  };
  const res = await apiService(request);
  return res
}

const getProposal = async (gigId, engineerId) => {
  const request = {
    method: 'get',
    url: `/gig/proposal/${gigId}/${engineerId}`
  };
  const res = await apiService(request);
  return res
}

const updateProposalStatus = async (data) => {
  const request = {
    method: 'post',
    url: `/gig/proposal/accept-reject`,
    data
  };
  const res = await apiService(request);
  return res
}
export {
  saveProposal,
  getProposal,
  updateProposalStatus
}
