import { useCallback, useEffect, useState } from 'react'
import { getProposalHireData, gigDetailId } from '../services/gig';

function useHireData(gigId, proposalId) {
  const [isLoading, setIsLoading] = useState(false);
  const [hireData, setHireData] = useState();

  const fetchData = async () => {
    setIsLoading(true);
    const res = await getProposalHireData(gigId, proposalId);
    setIsLoading(false);
    if (res.data) {
      setHireData(res.data);
    }
  };

  useEffect(() => {
    if (gigId) {
      fetchData();
    }
  }, [gigId, proposalId]);

  const refresh = useCallback(() => {
    fetchData();
  }, []);

  return { data: hireData, isLoading, refresh }
}

export default useHireData
