import { useState } from 'react';
import React, { useEffect } from 'react'
import { Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from '@mui/material'
import { getProposal } from '../../services/proposal';
import { useSelector } from 'react-redux';

function ProposalActivity({ open, setOpen, callback, data }) {
  const profile = useSelector(state => state.profile);
  const [proposalData, setProposalData] = useState();
  useEffect(() => {
    if (open && data?._id) {
      getProposal(data._id).then(res => {
        // console.log("data",res)
      })
    }
  }, [open, data]);
  const handleClose = () => {
    setOpen(false);
    if (callback) {
      callback()
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "& .pic": {
            width: "80px",
            height: "100%",
            objectFit: "contain",
            borderRadius: "50%"
          },
          "& .close": {
            width: "72px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <Stack
          sx={{ pt: 2 }}>
          <Typography
            sx={{
              mt: 1,
              fontSiz: "14px",
              fontWeight: 600
            }}>
            Proposal Activity Log
          </Typography>
        </Stack>
        <IconButton
          disableRipple
          onClick={handleClose}
          size='small'>
          <img src={"/images/close.png"} alt='close' className='close' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      </DialogContent>
    </Dialog>
  )
}

export default ProposalActivity
