import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertChartOutlinedTwoToneIcon from '@mui/icons-material/InsertChartOutlinedTwoTone';
import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DraftsIcon from '@mui/icons-material/Drafts';
import GppBadIcon from '@mui/icons-material/GppBad';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupsIcon from '@mui/icons-material/Groups';
import ChatIcon from '@mui/icons-material/Chat';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ResetPasswordIcon from '../assests/ResetPasswordIcon.svg';
import { Description } from '@mui/icons-material';
import { ReactComponent as DashboardBusiness } from '../assests/Icons/DashboardBusiness.svg';
import { ReactComponent as PostNewGig } from '../assests/Icons/PostNewGig.svg';
import { ReactComponent as DraftGigs } from '../assests/Icons/DraftGigs.svg';
import { ReactComponent as MyHires } from '../assests/Icons/MyHires.svg';
import { ReactComponent as ActiveGigbusiness } from '../assests/Icons/ActiveGigbusiness.svg';
import { ReactComponent as ClosedGigs } from '../assests/Icons/ClosedGigs.svg';
import { ReactComponent as InactiveBusiness } from '../assests/Icons/InactiveBusiness.svg';
import { ReactComponent as DiscoverEngineers } from '../assests/Icons/DiscoverEngineers.svg';
import { ReactComponent as ChatsBusiness } from '../assests/Icons/ChatsBusiness.svg';
import { ReactComponent as DiscoverGigs } from '../assests/Icons/DiscoverGigs.svg';
import { ReactComponent as AppliedGigs } from '../assests/Icons/AppliedGigs.svg';
import { ReactComponent as CompletedGigs } from '../assests/Icons/CompletedGigs.svg';
import { ReactComponent as SavedGigs } from '../assests/Icons/SavedGigs.svg';
import { ReactComponent as AllGigs } from '../assests/Icons/AllGigs.svg';
import { ReactComponent as Reporting } from '../assests/Icons/Reporting.svg';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import './SideNavLink.css';

const sideNavLinks = (type = '') => {
  switch (type) {
    case 'engProfile':
      return [
        { text: 'My Profile', path: '/myprofile', icon: <HomeOutlinedIcon /> },
        { text: 'Basic Information', path: '/engineer', icon: <AccountBoxOutlinedIcon /> },
        {
          text: 'Employment History',
          path: '/employment',
          icon: <InsertChartOutlinedTwoToneIcon />
        },
        { text: 'Education', path: '/education', icon: <EmailOutlinedIcon /> },
        { text: 'Billing Details', path: '/bankdetails', icon: <AccountBalanceIcon /> },
      ];
    case 'ownProfile':
      return [
        { text: 'Representative Details', path: '/representative', icon: <PersonOutlineOutlinedIcon /> },
        {
          text: 'Company Details',
          path: '/companydetails',
          icon: <BusinessOutlinedIcon />
        },
        { text: 'Billing Details', path: '/bankdetails', icon: <AccountBalanceIcon /> },
      ];
    case 'engDashboard':
      return [
        {
          text: <div className='eng-dashboard-text'>Dashboard</div>,
          path: '/dashboard',
          icon: <DashboardBusiness />
        },
        {
          text: 'Discover Gigs',
          path: '/discover',
          subMenuRoutes: ['/discover/'],
          icon: <DiscoverGigs className='discover-gig-icon' />
        },
        {
          text: 'Applied Gigs',
          path: '/applied',
          subMenuRoutes: ['/applied/'],
          icon: <AppliedGigs className='AppliedGigs-gig-icon' />
        },
        {
          text: 'Active Gigs',
          path: '/active',
          subMenuRoutes: ['/active/','/gig/milestone-payment'],
          icon: <ActiveGigbusiness />
        },
        {
          text: 'Completed Gigs',
          path: '/complete',
          subMenuRoutes: ['/complete/'],
          icon: <CompletedGigs className='AppliedGigs-gig-icon' />
        },
        {
          text: 'Saved Gigs',
          path: '/saved',
          subMenuRoutes: ['/saved/'],
          icon: <SavedGigs className='SavedGigs-gig-icon' />
        },
        { text: 'Wallet Management', path: '/wallet-management', icon: <AccountBalanceIcon /> },
        {
          text: 'My Invoices',
          path: ['/engineer/invoices/summary/', '/engineer/invoices/paid/', '/engineer/invoices/pending/', '/engineer/invoices/rejected/'],
          subMenuRoutes: ['/engineer/invoices/summary/', '/engineer/invoices/paid/', '/engineer/invoices/pending/', '/engineer/invoices/rejected/'],
          icon: <Description sx={{ fontSize: '26px', marginLeft: '-3px' }} />,
          iconOpened: <ArrowDropUpIcon />,
          iconClosed: <ArrowDropDownIcon />,

          subNav: [
            {
              icon: 'Summary',
              path: '/engineer/invoices/summary',
            },
            {
              icon: 'Pending',
              path: '/engineer/invoices/pending',
            },
            {
              icon: 'Paid',
              path: '/engineer/invoices/paid',
            },
            {
              icon: 'Rejected',
              path: '/engineer/invoices/rejected',
            },
          ]
        },
        {
          text: 'Chats',
          path: '/echat',
          icon: <ChatsBusiness className='ChatsBusiness-gig-icon' />,
          notifications: true
        },
      ];
    case 'ownerDashboard':
      return [
        {
          text: <div className='business-dashboard-text'>Dashboard</div>,
          path: '/gig/dashboard',
          icon: <DashboardBusiness />
        },
        {
          text: <div className='eng-dashboard-text'>Post New Job</div>,
          path: '/gig/new',
          subMenuRoutes: ['/gig/edit/'],
          icon: <PostNewGig className='AppliedGigs-gig-icon' />
        },
        {
          text: 'Draft Gigs',
          path: '/gig/draft',
          icon: <DraftGigs className='ChatsBusiness-gig-icon' />
        },
        {
          text: 'My Hires',
          path: ['/hires/currenthires/', '/hires/pasthires/'],
          subMenuRoutes: ['/hires/currenthires/', '/hires/pasthires/'],
          icon: <MyHires className='AppliedGigs-gig-icon' />,
          iconOpened: <ArrowDropUpIcon />,
          iconClosed: <ArrowDropDownIcon />,

          subNav: [
            {
              icon: 'Current Hires',
              path: '/hires/currenthires',
            },
            {
              icon: 'Past Hires',
              path: '/hires/pasthires',
            }
          ]
        },
        {
          text: 'Active Gigs',
          path: '/gig/active-post',
          subMenuRoutes: ['/gig/active-post/','/gig/active-posts/milestone-payment'],
          icon: <ActiveGigbusiness />
        },
        {
          text: 'Active Posts',
          path: '/gig/active',
          subMenuRoutes: ['/gig/active/', '/gig/active/applicants/', '/gig/milestone-payment'],
          icon: <ActiveGigbusiness />
        },
        {
          text: 'Inactive Gigs',
          path: '/gig/inactive',
          subMenuRoutes: ['/gig/inact/', '/gig/inact/applicants/'],
          icon: <InactiveBusiness />
        },
        {
          text: 'Closed Gigs',
          path: '/gig/closed',
          subMenuRoutes: ['/gig/closed/', '/gig/closed/applicants/'],
          icon: <ClosedGigs />
        },
        {
          text: 'Discover Engineers',
          path: '/engineer/list',
          subMenuRoutes: ['/engineer/list', '/engineer/details/'],
          icon: <DiscoverEngineers />
        },
        { text: 'Wallet Management', path: '/wallet-management', icon: <AccountBalanceIcon /> },
        {
          text: 'My Invoices',
          path: ['/business/invoices/summary/', '/business/invoices/paid/', '/business/invoices/pending/', '/business/invoices/rejected/'],
          subMenuRoutes: ['/business/invoices/summary/', '/business/invoices/paid/', '/business/invoices/pending/', '/business/invoices/rejected/'],
          icon: <Description sx={{ fontSize: '26px', marginLeft: '-3px' }} />,
          iconOpened: <ArrowDropUpIcon />,
          iconClosed: <ArrowDropDownIcon />,

          subNav: [
            {
              icon: 'Summary',
              path: '/business/invoices/summary',
            },
            {
              icon: 'Pending',
              path: '/business/invoices/pending',
            },
            {
              icon: 'Paid',
              path: '/business/invoices/paid',
            },
            {
              icon: 'Rejected',
              path: '/business/invoices/rejected',
            },
          ]
        },
        {
          text: 'Chats',
          path: '/ochat',
          icon: <ChatsBusiness className='ChatsBusiness-gig-icon' />,
          notifications: true
        }
      ];
    case 'bussinessquery':
      return [
        {
          text: 'Raise a query',
          path: '/bussinessquery',
          icon: <HelpOutlineIcon />
        }
      ];
    case 'engineerquery':
      return [
        {
          text: 'Raise a query',
          path: '/engineerquery',
          icon: <HelpOutlineIcon />
        }
      ];
    case 'bussinesspasswordreset':
      return [
        {
          text: 'Reset Password',
          path: '/bussinesspasswordreset',
          icon: <img sx={{ color: '#0000FF' }} src={ResetPasswordIcon} alt="resetpasswordicon" fontSize="medium" width="12%" />
        }
      ];
    case 'engineerpasswordreset':
      return [
        {
          text: 'Reset Password',
          path: '/engineerpasswordreset',
          icon: <img sx={{ color: '#0000FF' }} src={ResetPasswordIcon} alt="resetpasswordicon" fontSize="medium" width="12%" />
        }
      ];
    case 'admin':
      return [
        {
          text: 'User Account Manager',
          path: ['/admin/engineer/profile/', '/admin/business/profile/'],
          subMenuRoutes: ['/admin/engineer/profile/', '/admin/business/profile/'],
          icon: <ManageAccountsIcon />,
          iconOpened: <ArrowDropUpIcon />,
          iconClosed: <ArrowDropDownIcon />,

          subNav: [
            {
              icon: 'Engineer Profiles',
              path: '/admin/engineer/profile',
            },
            {
              icon: 'Business Profiles',
              path: '/admin/business/profile',
            },
            {
              icon: 'Unverified Profiles',
              path: '/admin/unverified/profile',
            }
          ]
        },
        {
          text: 'All Gigs',
          path: '/admin/owner/allgigs/details',
          subMenuRoutes: ['/admin/owner/allgigs/details', '/admin/owner/allgigs/details/admin/owner/allgigs/details/'],
          icon: <AllGigs />
        },
        {
          text: 'Reporting',
          path: '/admin/reporting',
          icon: <Reporting />
        },
        {
          text: 'Invoices',
          path: '/admin/invoices',
          icon: <Description sx={{ fontSize: '28px', marginLeft: '-1.6px' }} />
        },
      ];

    default:
      return [];
  }
};

export default sideNavLinks;

