import React from 'react';
import { Container, Box, CircularProgress } from '@mui/material';

const PageLoader = ({ sx = {} }) => {
  return (
    <Container>
      <Box
        sx={{
          maxWidth: 640,
          margin: 'auto',
          textAlign: 'center',
          marginTop: '50px',
          minHeight: '80vh',
          ...sx
        }}
      >
        <CircularProgress />
      </Box>
    </Container>
  );
};

export default PageLoader;
