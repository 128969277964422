import { getCookie } from './cookie';

export const isRegisteredUser = () => {
  return !!sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : getCookie('access_token') && !!sessionStorage.getItem("refresh_token") ? sessionStorage.getItem("refresh_token") : getCookie('refresh_token');
};

export const tokenHeaders = () => {
  const At = !!sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : getCookie('access_token');
  const Rt = !!sessionStorage.getItem("refresh_token") ? sessionStorage.getItem("refresh_token") : getCookie('refresh_token');

  if (At && Rt) {
    return {
      access_token: At,
      refresh_token: Rt
    };
  }
  return {};
};

export const getUserType = () => {
  const userDetails = getTokenDetails();

  return userDetails.userRole;
};


export const getUserId = () => {
  const userDetails = getTokenDetails();

  return userDetails.userId;
};

export const getTokenDetails = () => {
  const At = !!sessionStorage.getItem("access_token") ? sessionStorage.getItem("access_token") : getCookie('access_token');

  return decodeToken(At) || {};
};

export const decodeToken = (token = '') => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
