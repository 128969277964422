import { useState } from "react";

const ReadMore = ({ children }) => {
  let text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="gig-detail-see-more">
      {isReadMore ? (text.length > 385 ? text.slice(0, 635) + '.....' : text) : text}
      <br></br>
      {text.length >= 385 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? 'See more' : 'See less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

export default ReadMore
