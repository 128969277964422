import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _toNumber from 'lodash/toNumber';
import _last from 'lodash/last';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import { getQueryParams } from '../../../utils/helpers';
import { getGigs, updateGigStatus } from '../../../services/gig';
import Prompt from '../../../components/Prompt/Prompt';
import ActionDropDown from '../../../components/DropDown/DropDown';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import { setNewGigDetails } from '../../../store/actions/newGig';
import '../gigDashboard/GigDashboard.css';
import Footer from '../../../components/footer1/footer';

const ActiveGigs = (props) => {
  const query = getQueryParams();
  const queryPage = query.page;
  const [open, setOpen] = useState(false);
  const [promptData, setPromptData] = useState(undefined);
  const [messageBody, setMessageBody] = useState('');
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const createData = (name, duration, rate, hours, location, id, dropdown) => {
    if (rate === "") {
      rate = "Not Disclosed";
    }
    return [name, duration, rate, hours, location, id, dropdown];
  };

  const resetPage = (data) => {
    const dd = getDropDown(data.id, data.index, data.hire);
    rows[data.index][5] = dd;
    setRows(rows);
  };

  const onPromptSelected = (data) => {
    setOpen(false);

    if (data.action == "Edit" && data.type == "yes") {
      history.push(`/gig/edit/${data.id}`);
      return;
    }

    if (data.action == "Close" && data.type == "yes") {
      history.push(`/gig/review/business/add/${data.id}`);
    }

    if (data.type === 'no') {
      resetPage(data);
    } else {
      resetPage(data);
      // action
      const obj = {
        _id: data.id,
        status: data.action === 'Close' ? 'CLOSED' : 'INACTIVE'
      };
      updateGigStatus(
        obj,
        (response) => {
          //error response
        },
        (response) => {
          if (data.action === 'Close') {
            socket.emit('postNotification', {
              "notificationDescription": `The ${data.name} has been closed`,
              "userRole": ["business"],
              "businessId": props.profile.basicInfo.userId,
              "isRead": false,
              "businessProfilePic": props.profile.myProfile.profilePic,
              "redirectUrl": "/discover",
            });
            socket.emit('postNotification', {
              "notificationDescription": `The ${data.name} has been closed`,
              "userRole": ["admin"],
              "isRead": false,
              "businessProfilePic": props.profile.myProfile.profilePic,
            });
          }
          //success response
          fetchData(page);
        }
      );
    }
  };

  const getDropDown = (id, index, applicationCount, hireCount) => {
    const onDropDownValueChange = (action) => {
      const valueObj = {
        Inactive: 'deactivate',
        Close: 'close'
      };
      if (action !== 'Active') {
        setPromptData({ action, id, index });
        setMessageBody(`Do you want to ${valueObj[action]} this gig?${valueObj[action] == 'close' ? '\nYou’ll be prompted to provide feedback in the following steps' : ''}`);
        setOpen(true);
      }
    };

    let count;
    if (applicationCount === 0 && hireCount === 0) {
      count = 0;
    } else {
      count = 1;
    }

    if (hireCount === 0) {
      return (
        <ActionDropDown
          dropDownChange={onDropDownValueChange}
          defaultValue={'Active'}
          values={['Active', 'Inactive']}
          editIcon={count}
          getEditOption={getEditOption}
          id={id}
        />
      )
    } else if (hireCount >= 1) {
      return (
        <ActionDropDown
          dropDownChange={onDropDownValueChange}
          defaultValue={'Active'}
          values={['Active', 'Close']}
          editIcon={count}
          getEditOption={getEditOption}
          id={id}
        />
      )
    }
  };

  const getEditOption = (action, id) => {
    if (action == 'Edit') {
      setPromptData({ action, id });
      setMessageBody(`Do you want to ${action} this gig?`);
      setOpen(true);
    }
  };

  const setData = (data) => {
    const rowData = [];
    const gigList = _get(data, 'gigList', []);
    const paginationObject = _get(data, 'paginationResult', {});
    for (let i = 0; i < gigList.length; i++) {
      const gig = gigList[i];
      const name = _get(gig, 'titleAndSkills.projectTitle', '');
      const duration = _get(gig, 'applicantCount', 0);
      const hireCount = _get(gig, 'hireCount', 0);
      const rate = _get(gig, 'scopeAndBudget.hourlyRate', '') ? `${_get(gig, 'scopeAndBudget.currency', 'R')} ${_get(gig, 'scopeAndBudget.hourlyRate', '')} /hr` : '';
      const hours = _get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '') ? `${_get(gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} hrs/week` : '';
      const location = _get(gig, 'deliverablesAndLocation.location.country', '') ? _get(gig, 'deliverablesAndLocation.location.country', '') : "Remote";
      const id = _get(gig, '_id', '');

      const objLocation = _get(gig, 'deliverablesAndLocation.location', '');
      const startDate = _get(gig, 'scopeAndBudget.startDate', '');

      rowData.push(
        createData(name, duration, rate, hours, location, getDropDown(id, i, duration, hireCount), { id, name, objLocation, startDate })
      );
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      status: ['ACTIVE', 'HIRE', 'APPROVED']
    };
    setIsLoading(true);
    getGigs(obj, setData).then(() => {
      setIsLoading(false);
    }).catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    fetchData(page);
  }, [paginationVal]);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const handleRowClick = (data) => {
    const gigData = _last(data);
    props.setNewGigDetails(gigData);
    history.push(`/gig/active/applicants/${gigData.id}?page=${page}&gigname=${gigData.name}`);
  };

  const handleRClick = (data) => {
    const gigData = _last(data);
    history.push(`/gig/active/${gigData.id}`);
  };


  const redirectToPostNewGig = () => {
    history.push('/gig/new');
  };

  const headers = ['GIG NAME', 'APPLICATIONS', 'RATE', 'AVAILABILITY', 'LOCATION', 'ACTIONS'];

  return (
    <>
      <PaddedBox style={{ textAlign: 'center', paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, minHeight: "580px" }}>
        <Grid container>
          <Typography
            className='font-fix'
            variant="h6"
            component="h6"
            color="pColor.main"
          >
            Active Posts
          </Typography>
          <Grid container className="table-Padding">
            <GridTable
              isLoading={isLoading}
              headers={headers}
              rows={rows}
              handleRowClick={handleRowClick}
              handleRClick={handleRClick}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(page)}
              emptyMessage="You don’t have any Active Gigs, would you like to create one?"
              emptyBoxButtonText="Post a Gig"
              onEmptyBoxButtonClick={redirectToPostNewGig}
            ></GridTable>
          </Grid>
        </Grid>
        {open ? (
          <Prompt
            message={messageBody}
            show={open}
            heading="Confirmation"
            data={promptData}
            values={['yes', 'no']}
            onPromptSelected={(data) => {
              onPromptSelected(data);
            }}
          ></Prompt>
        ) : (
          <></>
        )}
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    newGig: state.newGig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigDetails: (data) => dispatch(setNewGigDetails(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveGigs);

// export default ActiveGigs;
