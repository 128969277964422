import React, { useMemo, useState } from 'react';
import { Link, Typography, Box, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, DialogActions, Button, CircularProgress, FormGroup, Checkbox } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { formatDate } from '../../utils/helpers';
import { addDays } from 'date-fns';
import { useSelector } from 'react-redux';

const GigWorkContract = ({
  showModal,
  setShowModal,
  callback,
  rowData,
  gigData, }) => {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const profile = useSelector(state => state.profile);
  const [checked, setChecked] = useState(true);
  const handleClose = () => {
    setShowModal(false);
  }

  // console.log("rowData", gigData, rowData, profile)
  const onClick = async () => {
    try {
      if (callback) {
        setIsLoading(true);
        await callback(rowData);
        setIsLoading(false);
      }
      history.push(`/gig/milestone-payment/${gig._id}`)
    } catch (error) {
      setIsLoading(false);
    }
  }

  const engineerData = useMemo(() => {
    return {
      name: rowData?.userId?.profileData?.basicInfo?.name || '',
      title: rowData?.userId?.profileData?.myProfile?.title || '',
      profilePic: rowData?.userId?.profileData?.myProfile?.profilePic || '',
      id: rowData?.userId?.profileData?.basicInfo?.userId,
    };
  }, [rowData]);
  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      fullWidth
      maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          "& .logo": {
            width: "300px",
            height: "100%",
            objectFit: "contain"
          },
          "& .close": {
            width: "72px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <img src={"/images/logo.png"} alt='logo' className='logo' />
        <IconButton
          disableRipple
          onClick={handleClose}
          size='small'>
          <img src={"/images/close.png"} alt='close' className='close' />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{
          fontFamily: 'Arial, sans-serif',
          padding: 2,
          "& h6": {
            fontSize: "16px",
            fontWeight: 600,
            "span": {
              fontSize: "16px",
              fontWeight: 400,
            }
          },
          "ul": {
            mt: 0
          },
          "ol": {
            mt: 0
          }
        }}>
          <Typography variant="h4" align="center" sx={{ marginBottom: 3, fontSize: "24px", fontWeight: 600 }}>
            Gig Work Contract for Engineering Services
          </Typography>

          <Typography variant="h6">Parties Involved:</Typography>
          <Typography variant="h6">Client: <Typography component={"span"}>{profile?.companyDetails?.companyName}</Typography></Typography>
          <Typography variant="h6">Engineer: <Typography component={"span"}>{engineerData?.name}</Typography></Typography>
          <Typography variant="h6" sx={{ mt: 1 }}>Contract ID: <Typography component={"span"}>{engineerData?.name}</Typography></Typography>
          <Typography variant="h6">Revision: 1</Typography>

          <Typography variant="h6" sx={{ mt: 2 }}>Project Description:</Typography>

          <div dangerouslySetInnerHTML={{ __html: gigData?.titleAndSkills?.projectDetails || "" }} />

          <Typography variant="h6" sx={{ marginTop: 2 }}>Milestones and Payments:</Typography>
          <ol>
            {
              rowData?.proposalData?.milestones?.map((milestone, index) => {
                return (
                  <li key={index}>
                    <Typography variant="h6">
                      Milestone {index + 1}:
                      <Typography component={"span"} sx={{ ml: 1 }}>
                        {milestone?.title} - {gigData?.scopeAndBudget.currency || 'R'} {milestone?.amount}
                      </Typography>
                    </Typography>
                  </li>
                )
              })
            }
          </ol>

          <Typography variant="h6" sx={{ marginTop: 1 }}>
            Total Contract Amount: <Typography component={"span"}>{gigData?.scopeAndBudget.currency || 'R'} {gigData?.scopeAndBudget?.budget}</Typography>
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>Timeline:</Typography>
          <ul>
            <li>
              <Typography variant="h6">Start Date:
                <Typography component={"span"} sx={{ ml: 1 }}>
                  {
                    gigData?.scopeAndBudget?.startDate && formatDate(gigData?.scopeAndBudget?.startDate)
                  }
                </Typography>
              </Typography>
            </li>
            <li>
              <Typography variant="h6">End Date:
                <Typography component={"span"} sx={{ ml: 1 }}>
                  {
                    (gigData?.scopeAndBudget?.startDate &&
                      gigData?.scopeAndBudget?.projectDurationInDays)
                    &&
                    formatDate(addDays(new Date(gigData?.scopeAndBudget?.startDate), gigData?.scopeAndBudget?.projectDurationInDays || 0))
                  }
                </Typography>
              </Typography>
            </li>
          </ul>

          <Typography variant="h6" sx={{ marginTop: 2 }}>Responsibilities:</Typography>
          <ul>
            <li>
              <Typography variant="body1">
                <strong>Business:</strong> Provide all necessary information and support required for the engineer to complete the project.
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                <strong>Engineer:</strong> Perform the engineering services as described, ensuring all deliverables meet the agreed-upon specifications and quality standards.
              </Typography>
            </li>
          </ul>

          <Typography variant="h6" sx={{ marginTop: 2 }}>Confidentiality:</Typography>
          <Typography variant="body1">
            Both parties agree to comply with global privacy standards, including GDPR and other relevant regulations. The engineer will keep all project-related information confidential and not disclose any details to third parties without the business's consent. The business agrees to keep all personal information provided by the engineer confidential.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>Termination:</Typography>
          <Typography variant="body1">
            This contract may be terminated at any time by mutual agreement of both parties. In the event of termination, the engineer will be compensated for work completed up to the last completed milestone.
          </Typography>

          <Typography variant="h6" sx={{ marginTop: 2 }}>Terms and Conditions:</Typography>
          <Typography variant="body1">
            For definitions and general terms and conditions governing this engagement, please refer to the <Link href="https://gigengineer.io/" target="_blank" rel="noopener noreferrer">Gig Engineer's website.
            </Link>
          </Typography>
        </Box>
        <FormGroup
          sx={{
            color: "#0140AA"
          }}>
          <FormControlLabel control={<Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            sx={{
              color: "#0140AA",
              '&.Mui-checked': {
                color: "#0140AA",
              },
            }} />} label="By checking this box, you are agreeing to our terms of service." />
        </FormGroup>
        <Box sx={{ my: 2, border: "0.82px solid #D5E0D5" }} />
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>Cancel</Button>
        <Button
          disabled={!checked || isLoading}
          variant='contained'
          sx={{ background: "#0140AA" }}
          onClick={onClick}>Approve {isLoading && <CircularProgress size={20} color='info' sx={{ ml: 1 }} />}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default GigWorkContract;
