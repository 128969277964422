import { Button, Card, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Modal, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useMemo, useState } from 'react'
import { initiateTruzoRegistration } from '../../../../services/wallet';
import { useLocation } from 'react-router-dom';
import CustomModal from '../../../../components/common/CustomModal';
import config from '../../../../config';
import { useHistory } from 'react-router-dom';
import { getUserType } from '../../../../utils/user';


function TruzoWallet({ data, isTruzoLinked }) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const userType = getUserType();

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const success = queryParams.get('success');
      const msg = queryParams.get("msg");
      console.log("success", success, msg)
      if (success) {
        setShowModal(true);
      }

    }
  }, [location]);

  const walletInfo = useMemo(() => {
    if (data && data?.profile) {
      return {
        fullName: data.profile?.name || "",
        email: data.profile?.email || "",
        phoneNumber: data.profile?.mobile || "",
        companyName: data.profile?.company_name || "",
        verified: isTruzoLinked,
      }
    }
    return null

  }, [data, isTruzoLinked])

  // console.log("walletInfo", walletInfo, data)

  return (
    <Card
      sx={{
        mt: 2,
        px: 2,
        py: 2,
        position: "relative",
        "& img": {
          width: "100%",
          height: "137px",
          objectFit: "contain",
        }
      }}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={12} lg={8}>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              [theme.breakpoints.down("md")]: {
                flexDirection: "column"
              }
            }}>
            <Box>
              <img src={"/images/truzo-logo.png"} alt='truzo' />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <Typography
                sx={{
                  fontSize: "30px",
                  lineHeight: "30px",
                  fontWeight: 600,
                  color: "#27A0B4",
                  [theme.breakpoints.down("md")]: {
                    fontSize: "20px",
                    lineHeight: "20px",
                  }
                }}>
                {
                  walletInfo?.verified ?
                    "Your Truzo Account Has Been Linked" :
                    "Truzo is Not Linked Yet"
                }

              </Typography>
              {
                walletInfo ?
                  <Box
                    sx={{
                      "& .label": {
                        fontSize: "14px",
                        lineHeight: "30px",
                        color: "#575757",
                        py: "0px !important",
                        px: "10px",
                        width: "140px"
                      },
                      "& .value": {
                        fontSize: "14px",
                        lineHeight: "30px",
                        fontWeight: 600,
                        color: "#575757",
                        py: "0px !important",
                        px: "10px"
                      }
                    }}>
                    {
                      walletInfo?.companyName &&
                      <Box sx={{ display: "flex" }}>
                        <Typography className="label">Company Name:</Typography>
                        <Typography className="value">{walletInfo?.companyName}</Typography>
                      </Box>
                    }
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Full Name:</Typography>
                      <Typography className="value">{walletInfo?.fullName}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Email:</Typography>
                      <Typography className="value">{walletInfo?.email}</Typography>
                    </Box>
                    <Box sx={{ display: "flex" }}>
                      <Typography className="label">Phone Number:</Typography>
                      <Typography className="value">{walletInfo?.phoneNumber}</Typography>
                    </Box>
                  </Box>
                  :
                  <Typography
                    sx={{
                      fontSize: "18px",
                      lineHeight: "30px",
                      fontWeight: 400,
                      color: "#575757",
                      [theme.breakpoints.down("md")]: {
                        fontSize: "16px",
                        lineHeight: "20px",
                      }
                    }}>To enable seamless transactions for your gig activities, please link your Truzo account.</Typography>
              }
            </Box>
          </Box>
        </Grid>
        <Grid item sm={12} md={4} lg={4} textAlign={"end"} alignSelf={"center"}>
          <Box>
            {walletInfo?.verified &&
              <Box
                sx={{ position: "absolute", top: "10px", right: "20px", }}>
                <Chip label="VERIFIED" size="medium" sx={{ background: "#34C759", color: "#ffffff", fontWeight: 500 }} />
              </Box>
            }
            <Button
              size='large'
              onClick={() => {
                if (!walletInfo) {
                  initiateTruzoRegistration()
                } else {
                  window.open(config.TRUZO_ACCOUNT, "_blank")
                }
              }}
              sx={{
                width: "300px",
                backgroundImage: "linear-gradient(#ff8008, #ff8008, #ffc837)",
                color: "#ffffff",
                borderRadius: "5px",
              }}>
              {
                walletInfo ?
                  "PROCEED WITH MY ACCOUNT"
                  :
                  "Create your truzo account"
              }
            </Button>
          </Box>
        </Grid>
      </Grid>
      <CustomModal
        title='Truzo Account Linked Successfully!'
        content='Awesome! Your Truzo account is now successfully linked to GigEngineer. Start managing your gigs with seamless payment and contract features.'
        showModal={showModal}
        type={"success"}
        setShowModal={setShowModal}
        onSubmit={() => {
          if (userType === 'engineer') {
            history.push("/discover")
            return
          }
          history.push("/gig/dashboard")
        }
        } />

    </Card>
  )
}

export default TruzoWallet
