import { SET_CHAT_MSG, SET_CHAT_NEWUSER, SET_CHAT_ALL, SET_CHAT_MSGS, SET_CHAT_SELECTED_USER, SET_RECEIVED_MSG, SET_CHAT_NOTIFIER, SET_CHAT_USER_NAME, SET_NOTIFICATION } from '../actions/chat';
import _get from 'lodash/get';
import _pull from 'lodash/pull';
import _without from 'lodash/without';
import _each from 'lodash/each';
import _isEmpty from 'lodash/isEmpty';
import _clone from 'lodash/clone';
import { toast } from 'react-toastify';

const Initial_value = {
  selectedChat: '',
  users: [],
  messages: [],
  chatNotifications: [],
  notifier: false,
  newUser: {},
  notifications: [],
};

const localChat = (state = Initial_value, action) => {
  switch (action.type) {
    case SET_CHAT_MSG: {
      const payload = _get(action, 'payload', {});
      return {
        ...state,
        messages: [...state.messages, payload]
      };
    };
    case SET_NOTIFICATION: {
      const payload =  _get(action, 'payload', {});
      return {
        ...state,
        notifications: [payload]
      };
    };
    case SET_RECEIVED_MSG: {
      const payload = _get(action, 'payload', {});
      const msgs = [...state.messages];
      const list = [...state.users];
      const actionkey = (payload.senderId < payload.receiverId) ? `${payload.senderId}-${payload.receiverId}` : `${payload.receiverId}-${payload.senderId}`;
      let notifications = [...state.chatNotifications];
      if (state.selectedChat === '') {
        toast.info(<div>
          <h4 className='chatToastContent'>{payload.senderName}</h4>
          <p className='chatToastContent'>{payload.messageType === 'FILE' ? payload.data.message.fileName : payload.data.message}</p>
        </div>, { position: "top-right" });
      }

      if (state.selectedChat === '' && state.users.length === 0) {
        msgs.push(payload);
        const chatUser = {
          chatId: payload.cId,
          name: payload.senderId,
          otherUserId: payload.senderId
        }
        list.push(chatUser);
      }

      if (state.selectedChat === payload.cId) {
        msgs.push(payload);
      } else {
        notifications = [...new Set([...notifications, actionkey])];
      }

      return {
        ...state,
        users: list,
        messages: msgs,
        chatNotifications: notifications
      };
    };
    case SET_CHAT_NEWUSER:
      return {
        ...state,
        newUser: _get(action, 'payload', {}),
        selectedChat: _get(action, 'payload.chatId', '')
      };
    case SET_CHAT_ALL: {
      let userList = _get(action, 'payload', []);
      let existingUser = false;
      
      // if (!_isEmpty(state.newUser)) {
      //   _each(userList, (user) => {
      //     if (user.otherUserId === state.newUser.otherUserId) {
      //       existingUser = true;
      //     }
      //   });
      //   if (!existingUser) {
      //     userList = [...new Set([...userList, state.newUser])]
      //   }
      // }
      return {
        ...state,
        users: userList,
        notifier: false
      };
    };
    case SET_CHAT_MSGS:
      return {
        ...state,
        messages: _get(action, 'payload', [])
      };
    case SET_CHAT_SELECTED_USER:
      return {
        ...state,
        chatNotifications: [..._pull(state.chatNotifications, _get(action, 'payload', ''))],
        selectedChat: _get(action, 'payload', '')
      };
    case SET_CHAT_NOTIFIER:
      return {
        ...state,
        notifier: _get(action, 'payload', false)
      };
    case SET_CHAT_USER_NAME:
      const chatUser = _get(action, 'payload.0', {});
      if(state.newUser.otherUserId === chatUser.otherUserId) {
        state.newUser.name =  chatUser.name;
        state.newUser.profilePic = chatUser.profilePic;
      }
      _each(state.users, (user) => {
        if (user.otherUserId == chatUser.otherUserId) {
          user.name = chatUser.name;
          user.profilePic = chatUser.profilePic;
        }
      })
      return {
        ...state,
        users: [...state.users],
        newUser: _clone(state.newUser)
      }
    default:
      return state;
  }
};

export default localChat;
