import * as yup from 'yup';

const NameValidation = (field) => yup.string().required(`${field} is required`)
  .min(2, 'name should be of minimum 2 characters length');

const mobileValidation = yup
  .string('Enter your mobile number')
  .min(10, 'Mobile Number should be of minimum 10 characters length')
  .required('Mobile Number is required');

const stringValidation = (field) =>
  yup.string(`Enter your ${field} name`).required(`${field} is required!`).nullable();

const objectValidation = (field) =>
  yup.array(`Enter your ${field} name`).required(`${field} is required`);

const emailValidation = (field) => yup.string().email('Invalid email').required(`${field} is required`);

const pswdValidation = yup
  .string()
  .required('Password is required')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
  );

const currentValidation = yup
  .string()
  .required('Current password is required!');

const newPsdValidation = yup
  .string()
  .required('New password is required!')
  .matches(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
  );

const confirmPsdValidation = yup
  .string()
  .required('Confirm new password is required!')
  .oneOf([yup.ref("newPassword"), null], "Password does not match!")

const fullNameValidation = yup.string().required('Full Name is required ');

const stringNotRequired = (field) => yup.string(`Enter your ${field} name`);

const nameValidationRequired = (field) => yup.string().required(`${field} is required `);

const urlvalid = (field) => yup.string()
  .matches(
    /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g,
    `Enter correct ${field}!`
  );

export const engineerScreenValidationSchema = yup.object({
  name: NameValidation('Name'),
  mobileNumber: mobileValidation,
  areaCode: stringValidation('Area Code'),
  address: stringValidation('Address'),
  languageList: yup.array().of(
    yup.object().shape({
      language: stringValidation('Language'),
      proficienyLevel: stringValidation('Proficieny Level')
    })
  )
});

export const myprofileScreenValidationSchema = yup.object({
  title: stringValidation('Title'),
  overview: stringValidation('Overview'),
  userName: stringValidation('Username'),
  hourlyRate: stringValidation('Hourly Rate')
});

export const billingValidation = yup.object({
  holderName: stringValidation('Account Holder Name'),
  bankName: stringValidation('Bank Name'),
  accountNumber: stringValidation('Account Number'),
  accountType: stringValidation('Account Type')
});

export const signInScreenValidationSchema = yup.object({
  emailId: emailValidation('Email'),
  password: pswdValidation
});

export const resetPasswordScreenValidationSchema = yup.object({
  password: pswdValidation,
  confirmPassword: pswdValidation
});

export const forgotPasswordScreenValidationSchema = yup.object({
  emailId: emailValidation('Email')
});

export const educationValidationSchema = yup.object({
  education: yup.array().of(
    yup.object().shape({
      instituteName: stringValidation('School/College/Institute'),
      degree: stringValidation('Name of Degree'),
      areaOfSpecialization: stringNotRequired('Area of Degree/Specialisation'),
      fromDate: stringValidation('Start Date')
    })
  ),
  publication: yup.array().of(
    yup.object().shape({
      publicationName: stringNotRequired('Publication Details'),
      publicationUrl: urlvalid('Publication Url')
    })
  )
});

export const representativeValidationSchema = yup.object({
  name: nameValidationRequired('Name'),
  mobileNumber: mobileValidation,
  designation: stringValidation('Title'),
  description: stringNotRequired('Description')
});

export const companyDetailsValidationSchema = yup.object({
  companyName: stringValidation('Company name'),
  websiteUrl: urlvalid('Website URL'),
  registerNumber: stringNotRequired('Registration Number'),
  tinNumber: stringNotRequired('TIN Number'),
  linkedinUrl: urlvalid('Linkedin URL'),
  contactNumber: mobileValidation,
  physicalAddress: stringValidation('Address'),
  description: stringNotRequired('Description'),
  areaCode: stringValidation('Area Code')
});


export const employmentValidationSchema = yup.object({
  employment: yup.array().of(
    yup.object().shape({
      companyName: stringValidation('Name of Company/Organisation'),
      designation: stringValidation('Job Designation'),
      fromDate: stringValidation('Start Date')
    })
  ),
  referencesList: yup.array().of(
    yup.object().shape({
      name: stringValidation('Name of Reference'),
      emailId: emailValidation('Email ID of Reference')
    })
  ),
  reviewsList: yup.array().of(
    yup.object().shape({
      nameOfSource: stringValidation('Name of source'),
      reviewLink: stringValidation('Source URL'),
      rating: stringValidation('Rating'),
      outOf: stringValidation('Out of'),
    })
  ),
});

export const expertiseScreenValidationSchema = yup.object({
  tools: yup.array().of(
    yup.object().shape({
      level: stringValidation('tool level'),
      expertiseName: stringValidation('tool')
    })
  ),
  skills: yup.array().of(
    yup.object().shape({
      level: stringValidation('skill level'),
      skillName: stringValidation('skill')
    })
  )
});

export const certificationScreenValidationSchema = yup.object({
  certification: yup.array().of(
    yup.object().shape({
      certificationFile: stringNotRequired('tool level'),
      certificateName: stringValidation('Certification Details')
    })
  ),
  publication: yup.array().of(
    yup.object().shape({
      publicationFile: stringNotRequired('skill level'),
      publicationName: stringNotRequired('skill'),
      publicationUrl: urlvalid('Publication URL'),
    })
  )
});

export const signUpScreenValidationSchema = yup.object({
  fullName: fullNameValidation,
  emailId: emailValidation('Email'),
  password: pswdValidation
});

export const resetPasswordValidationSchema = yup.object({
  oldPassword: currentValidation,
  newPassword: newPsdValidation,
  confirmPassword: confirmPsdValidation
});

export const newGigProjectDetailsSchema = yup.object({
  projectTitle: stringValidation('Project Title'),
  projectDetails: stringValidation('Project Details'),
  fieldOfSpecialization: yup.array().required("Field of engineering is required")
  // skillsRequired: yup.array().of(
  //   yup.object().shape({
  //     engineerSkill: stringValidation('Skill Name'),
  //     skillProficiency: stringNotRequired('Skill Proficiency')
  //   })
  // )
});

export const newGigProjectDurationSchema = yup.object({
  projectDuration: yup
    .number()
    .typeError('You must specify a number')
    .required('Project Duration is requried'),
  startDate: stringValidation('Start Date').required('Start Date is required'),
  availabilityHoursPerWeek: stringValidation('Availability'),
  // hourlyRate: yup
  //   .number()
  //   .required('Hourly Rate is requried')
  //   .typeError('You must specify a number'),
  budget: yup.number().required('Budget is requried').typeError('you must specify a number'),
  milestones: yup.array()
    .of(
      yup.object().shape(
        {
          "title": yup.string().required("Title is required"),
          "description": yup.string().required("Description is required"),
          "deadline": yup.string().required(),
          "amount": yup.string().required("Amount is required"),
          "deliverables": yup.array().optional()
        }
      )
    )
});

export const newRecruitmentSchema = yup.object({
  contractDuration: yup
    .number()
    .typeError('You must specify a number')
    .required('Contract Duration is requried'),
  startDate: stringValidation('Start Date').required('Start Date is required'),
  availabilityPercentage: yup.number().required("Availability is required"),
  baseSalary: yup.number().required("Base salary is required")
  // hourlyRate: yup
  //   .number()
  //   .required('Hourly Rate is requried')
  //   .typeError('You must specify a number'),
  // budget: yup.number().required('Budget is requried').typeError('you must specify a number')
});

export const newGigProjectDeliverablesSchema = yup.object({
  location: yup.object({
    country: stringNotRequired('Country'),
    state: stringNotRequired('Province'),
    city: stringNotRequired('City')
  }),
  isRemote: stringValidation('Prefered Remote'),
  // deliverables: yup.array().of(
  //   yup.object().shape({
  //     deliverableTitle: stringValidation('Deliverable Title'),
  //     deliverableDesc: stringNotRequired('Deliverable Description')
  //   })
  // )
});

export const InvoiceSchema = yup.object({
  gigName: stringValidation('gigName'),
  description: stringNotRequired('description'),
});
