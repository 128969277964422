import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Box,
  TextField,
  Typography,
  Input
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { blue } from '@mui/material/colors';
import HelpIcon from '@mui/icons-material/Help';
import _toNumber from 'lodash/toNumber';
import { Formik, Form } from 'formik';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { newGigProjectDurationSchema, newRecruitmentSchema } from '../../../../utils/formikValidations';
import FormTextField from '../../../../components/util/FormTextField';
import { setNewGigStep, setNewGigSuccess, setNewGigError } from '../../../../store/actions/newGig';
import { saveNewDraftGigApi } from '../../../../services/gig';
import { ButtonContainerStep2, CalendarBox, DraftBtnStep2 } from '../../../app.styles';
import StepHeader from './StepHeader';
import InputAdornment from '@mui/material/InputAdornment';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import Step2 from '../../../../assests/Step2.png';
import DeleteImg from '../../../../assests/Delete_Icon.png';
import hours from '../../../../assests/hours.png';
import days from '../../../../assests/days.jpg';
import weeks from '../../../../assests/week.png';
import months from '../../../../assests/month.png';
import './Step3.scss';
import './Step2.scss';
import { getDurationLabel, getContractDurationLabel, scrollOnTop } from '../../../../utils/helpers';
import { PaddedBox } from '../../../app.styles';
import { useHistory } from 'react-router-dom';

const numberFormatCustom = React.forwardRef(function numberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
});

numberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const calculateDefaultDate = (startDate) => {
  const start = new Date(startDate); // Convert to Date object
  const nextMonth = new Date(start.getFullYear(), start.getMonth() + 1, 1); // Start of next month
  return new Date(nextMonth - 1); // Subtract one day to get the end of the current month
};

const Step = (props) => {
  const his = useHistory();
  const scopeAndBudget = _get(props, 'newGig.scopeAndBudget', props.defaultObject.scopeAndBudget);
  const [currency, setCurrency] = useState(scopeAndBudget.currency || 'R');
  const [durationLabel, setdurationLabel] = useState('Hours');
  const [contractDurationLabel, setcontractDurationLabel] = useState('Months');
  const hoursbydefaulf = props.newGig?.scopeAndBudget?.durationUnit;
  const contractHoursByDefault = props.newGig?.scopeAndBudget?.contractDurationUnit;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  useEffect(() => {
    scrollOnTop();
    props.changeHeadTitle('Please define the scope of work.');
  }, [props]);

  useEffect(() => {
    setdurationLabel(
      props.newGig?.scopeAndBudget?.durationUnit === 'PERHOUR'
        ? 'Hours'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERDAY'
          ? 'Days'
          : props.newGig?.scopeAndBudget?.durationUnit === 'PERWEEK'
            ? 'Weeks'
            : props.newGig?.scopeAndBudget?.durationUnit === 'PERMONTH'
              ? 'Months'
              : 'Hours'
    );
  }, []);

  useEffect(() => {
    setcontractDurationLabel(
      props.newGig?.scopeAndBudget?.contractHoursByDefault === 'MONTH'
        ? 'Months'
        : props.newGig?.scopeAndBudget?.contractHoursByDefault === 'DAYS'
          ? 'Days'
          : props.newGig?.scopeAndBudget?.contractHoursByDefault === 'PERMANENT' ?
            'Permanent'
            : 'Months'
    );
  }, []);

  const saveToDraft = ({
    values,
    validateForm,
    setTouched,
    newGig,
    setNewGigError,
    setNewGigSuccess
  }) => {
    validateForm().then((errors) => {
      if (_isEmpty(errors)) {
        // verify if errors object is equals to '{}' an empty object
        const { milestones, ...restValues } = values;
        const obj = {
          ...newGig,
          scopeAndBudget: {
            ...restValues,
            projectDurationInDays: _toNumber(values.projectDuration),
            durationUnit: values.durationUnit
          },
          milestones,
          status: 'DRAFT'
        };
        if (!!obj.milestones && obj.milestones.length > 0) {
          obj.milestones.map((item, index) => {
            item.amount = Number(item.amount)
          })
        }
        if (props?.newGig?.titleAndSkills?.jobType == "recruitment") {
          if (!!obj.scopeAndBudget && !!obj.scopeAndBudget.contractDuration) {
            obj.scopeAndBudget.contractDuration = Number(obj.scopeAndBudget.contractDuration)
          }
          delete obj?.scopeAndBudget?.projectDuration
          delete obj?.scopeAndBudget?.budget
          delete obj?.scopeAndBudget?.hourlyRate
          delete obj?.scopeAndBudget?.availabilityHoursPerWeek
          delete obj?.scopeAndBudget?.projectDurationInDays
          // delete obj?.scopeAndBudget?.milestones
        }
        saveNewDraftGigApi(obj, setNewGigError, setNewGigSuccess).then((e) => {
          if (e.status === 200) {
            if (e?.data?.gig?._id) {
              his.push(`/gig/edit/${e?.data?.gig?._id}`);
            } else {
              his.push('/gig/active');
            }
          }
          else {
            // toast.error(e.message);
          }
        }).catch((err) => {
          console.error(err)
        });
      } else {
        return setTouched(errors);
      }
    });
  };

  const activeBackBtn = () => {
    props.methodBackClick(1);
  };

  useEffect(() => {
    setdurationLabel(
      props.newGig?.scopeAndBudget?.durationUnit === 'PERHOUR'
        ? 'Hours'
        : props.newGig?.scopeAndBudget?.durationUnit === 'PERDAY'
          ? 'Days'
          : props.newGig?.scopeAndBudget?.durationUnit === 'PERWEEK'
            ? 'Weeks'
            : props.newGig?.scopeAndBudget?.durationUnit === 'PERMONTH'
              ? 'Months'
              : 'Hours'
    );
  }, []);

  useEffect(() => {
    setcontractDurationLabel(
      props.newGig?.scopeAndBudget?.contractHoursByDefault === 'MONTH'
        ? 'Months'
        : props.newGig?.scopeAndBudget?.contractHoursByDefault === 'DAYS'
          ? 'Days'
          : props.newGig?.scopeAndBudget?.contractHoursByDefault === 'PERMANENT' ?
            'Permanent'
            : 'Months'
    );
  }, []);

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '0px'
            : '0px'
            }`,
          marginTop: '-5px'
        }}
      >
        <div className="step2-main-heading">
          <div className="step2-heading-body">2/3 </div>
          {(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? 'Scope & Budget' : 'Job Conditions'}
        </div>
        <div className="step2-body">
          <div className="step2-img-container">
            <div className="scope-text">{(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? 'Please define the scope of work' : 'Please define the work conditions'}</div>
            <Typography
              sx={{ fontFamily: 'Poppins', fontSize: '14px', fontWeight: '500', color: '#7E84A3' }}
            >
              You can save changes at each step and continue from where you left off.
            </Typography>
            <div className="img-step2-profile">
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Post+a+Gig+Step+2.svg"
                alt="profile"
                style={{ width: '350px', height: '350px' }}
              />
            </div>
          </div>

          <div className="Formrik-Container-step2">
            <Formik
              initialValues={{
                ...scopeAndBudget,
                durationUnit: hoursbydefaulf ? hoursbydefaulf : 'PERHOUR',
                contractDurationUnit: contractHoursByDefault ? contractHoursByDefault : 'MONTH',
                milestones: (!!props.newGig?.milestones && props.newGig?.milestones?.length > 0) ? props.newGig?.milestones : [
                  {
                    "title": "",
                    "description": "",
                    "deadline": calculateDefaultDate(new Date()),
                    "amount": "",
                    "deliverables": []
                  }
                ]
              }}
              enableReinitialize={true}
              validationSchema={(props?.newGig?.titleAndSkills?.jobType == "recruitment") ? newRecruitmentSchema : newGigProjectDurationSchema}
              onSubmit={(values) => {
                const { milestones, ...restValues } = values;
                const obj = {
                  scopeAndBudget: {
                    ...restValues,
                    projectDurationInDays: _toNumber(values.projectDuration),
                    durationUnit: values.durationUnit
                  },
                  milestones
                };
                if (!!obj.milestones && obj.milestones.length > 0) {
                  obj.milestones.map((item, index) => {
                    item.amount = Number(item.amount)
                  })
                }
                if (props?.newGig?.titleAndSkills?.jobType == "recruitment") {
                  if (!!obj.scopeAndBudget && !!obj.scopeAndBudget.contractDuration) {
                    obj.scopeAndBudget.contractDuration = Number(obj.scopeAndBudget.contractDuration)
                  }
                  delete obj?.scopeAndBudget?.projectDuration
                  delete obj?.scopeAndBudget?.budget
                  delete obj?.scopeAndBudget?.hourlyRate
                  delete obj?.scopeAndBudget?.availabilityHoursPerWeek
                  delete obj?.scopeAndBudget?.projectDurationInDays
                  // delete obj?.scopeAndBudget?.milestones
                }
                props.setNewGigStep(obj);
              }}
            >
              {(formProps) => {
                const { values, setFieldValue, touched, errors, handleBlur } = formProps;
                useEffect(() => {
                  if (
                    props?.newGig?.titleAndSkills?.jobType === "fractional" &&
                    values.durationUnit == "PERMONTH" &&
                    values?.projectDuration > 0
                  ) {
                    const milestonesCount = values?.projectDuration;
                    if (values.milestones.length !== milestonesCount) {
                      const autoGeneratedMilestones = Array.from({ length: milestonesCount }, (_, index) => ({
                        title: `Milestone ${index + 1}`,
                        description: "",
                        deadline: "",
                        amount: "",
                        deliverables: []
                      }));
                      setFieldValue("milestones", autoGeneratedMilestones);
                    }
                  }
                }, [values]);
                return (
                  <Form noValidate autoComplete="off">
                    <div className="all-item-step2">


                      <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType == "recruitment") ? "inherit" : "none"}` }} className="main-container-url-2">
                        <p class="label-2">Contract duration {`(${contractDurationLabel})`}<span className="required">*</span></p>
                        <span class="input-container-2">
                          <Input
                            placeholder="Enter duration"
                            disableUnderline={true}
                            type='number'
                            name="contractDuration"
                            value={_get(values, 'contractDuration')}
                            InputLabelProps={{ shrink: true }}
                            {...formProps}
                            onChange={(e) => {
                              setFieldValue('contractDuration', e.target.value);
                            }}
                            disabled={contractDurationLabel == "Permanent"}
                            // error={
                            //   _get(touched, 'contractDuration') &&
                            //   Boolean(_get(errors, 'contractDuration'))
                            // }
                            required="true"
                            variant="outlined"
                          />
                          <Select
                            sx={{
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                            value={_get(values, 'contractDurationUnit')}
                            onChange={(event) => {
                              setFieldValue('contractDurationUnit', event.target.value);
                              setcontractDurationLabel(getContractDurationLabel(event.target.value));
                            }}
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                            className="dd-duration"
                          >
                            <MenuItem
                              value="MONTH"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={months}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Months
                            </MenuItem>
                            <MenuItem
                              value="DAYS"
                              sx={{ alignItems: 'center', display: 'flex', marginRight: '4px' }}
                            >
                              <img
                                src={days}
                                style={{
                                  height: 21,
                                  width: 21,
                                  marginRight: '22px',
                                  marginLeft: '-3px'
                                }}
                              />
                              Days
                            </MenuItem>
                            <MenuItem
                              value="PERMANENT"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={weeks}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Permanent
                            </MenuItem>
                          </Select>
                        </span>
                      </div>
                      <div className="error-msg-pro-duration">{errors.contractDuration}</div>

                      <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? "inherit" : "none"}` }} className="main-container-url-2">
                        <p class="label-2">Project duration {`(${durationLabel})`}<span className="required">*</span></p>
                        <span class="input-container-2">
                          <Input
                            fullWidth
                            placeholder="Enter duration"
                            type={"number"}
                            disableUnderline={true}
                            name="projectDuration"
                            value={_get(values, 'projectDuration')}
                            InputLabelProps={{ shrink: true }}
                            {...formProps}
                            onChange={(e) => {
                              setFieldValue('projectDuration', e.target.value);
                            }}
                            error={
                              _get(touched, 'projectDuration') &&
                              Boolean(_get(errors, 'projectDuration'))
                            }
                            required="true"
                            variant="outlined"
                          />
                          <Select
                            sx={{
                              boxShadow: 'none',
                              '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                            value={_get(values, 'durationUnit')}
                            onChange={(event) => {
                              setFieldValue('durationUnit', event.target.value);
                              setdurationLabel(getDurationLabel(event.target.value));
                            }}
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                            className="dd-duration"
                          >
                            <MenuItem
                              value="PERHOUR"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={hours}
                                style={{ height: 22, width: 22, marginRight: '20px' }}
                              />
                              Hours
                            </MenuItem>
                            <MenuItem
                              value="PERDAY"
                              sx={{ alignItems: 'center', display: 'flex', marginRight: '4px' }}
                            >
                              <img
                                src={days}
                                style={{
                                  height: 21,
                                  width: 21,
                                  marginRight: '22px',
                                  marginLeft: '-3px'
                                }}
                              />
                              Days
                            </MenuItem>
                            <MenuItem
                              value="PERWEEK"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={weeks}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Weeks{' '}
                            </MenuItem>
                            <MenuItem
                              value="PERMONTH"
                              sx={{ alignItems: 'center', display: 'flex' }}
                            >
                              <img
                                src={months}
                                style={{ height: 16, width: 16, marginRight: '20px' }}
                              />
                              Months
                            </MenuItem>
                          </Select>
                        </span>
                      </div>
                      <div className="error-msg-pro-duration">{errors.projectDuration}</div>

                    </div>

                    <div className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl fullWidth variant="outlined">
                          <InputLabel>Currency</InputLabel>
                          <Select
                            required
                            value={_get(values, 'currency')}
                            label="Currency"
                            onChange={(event) => {
                              setFieldValue('currency', event.target.value);
                              setCurrency(event.target.value);
                            }}
                          >
                            <MenuItem value={'R'}>R ZAR </MenuItem>
                            <MenuItem value={'$'}>$ Dollar</MenuItem>
                          </Select>
                        </FormControl>
                      </Box>
                    </div>

                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? "inherit" : "none"}` }} className="step2-currency">
                      <FormTextField
                        name="budget"
                        label="Budget"
                        value={_get(values, 'budget')}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">{currency}</InputAdornment>
                          )
                        }}
                        type="number"
                        {...formProps}
                        required
                      />
                    </div>

                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType == "recruitment") ? "inherit" : "none"}` }} className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="baseSalary"
                            label="Base Salary"
                            value={_get(values, 'baseSalary')}
                            required
                            onChange={(event) => {
                              const bSalary = Number(event.target.value);
                              setFieldValue('baseSalary', bSalary);
                            }}
                            InputProps={{
                              inputComponent: numberFormatCustom,
                              startAdornment: (
                                <InputAdornment position="start">{currency}</InputAdornment>
                              ),
                              // endAdornment: <InputAdornment position="start">/hr</InputAdornment>
                            }}
                            error={
                              touched?.baseSalary &&
                              Boolean(errors?.baseSalary)
                            }
                            helperText={
                              touched?.baseSalary && errors?.baseSalary
                            }
                          />
                        </FormControl>
                        <div
                          title="Base Salary means the minimum salary rate you are willing to pay for this work."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                    </div>

                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? "inherit" : "none"}` }} className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            name="hourlyRate"
                            label="Hourly rate"
                            value={_get(values, 'hourlyRate')}
                            onChange={(event) => {
                              let hRate = Number(event.target.value);
                              setFieldValue('hourlyRate', hRate);
                            }}
                            InputProps={{
                              inputComponent: numberFormatCustom,
                              startAdornment: (
                                <InputAdornment position="start">{currency}</InputAdornment>
                              ),
                              endAdornment: <InputAdornment position="start">/hr</InputAdornment>
                            }}
                          />
                        </FormControl>
                        <div
                          title="Hourly rate means the maximum hourly rate you are willing to pay for this work."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                    </div>


                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType == "recruitment") ? "inherit" : "none"}` }} className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl variant="outlined" fullWidth>
                          <TextField
                            type={"number"}
                            name="availabilityPercentage"
                            label="Availability"
                            value={_get(values, 'availabilityPercentage')}
                            onChange={(event) => {
                              setFieldValue('availabilityPercentage', !isNaN(event.target.value) ? Number(event.target.value) : 0);
                            }}
                            InputProps={{
                              // inputComponent: numberFormatCustom,
                              inputMode: 'numeric',
                              pattern: '[0-9]*',
                              min: 0,
                              max: 100,
                              inputProps: {
                                min: 0,
                                max: 100
                              },
                              // startAdornment: (
                              //   <InputAdornment position="start">{currency}</InputAdornment>
                              // ),
                              endAdornment: <InputAdornment position="start">%</InputAdornment>
                            }}
                            required
                            error={
                              touched?.availabilityPercentage &&
                              Boolean(errors?.availabilityPercentage)
                            }
                            helperText={
                              touched?.availabilityPercentage && errors?.availabilityPercentage
                            }

                          />
                        </FormControl>
                        <div
                          title="Availability% means how much availability the candidate should have in the day for this job. 100% means full-time."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                      {
                        !(_get(values, 'availabilityPercentage') >= 0 && _get(values, 'availabilityPercentage') <= 100)
                        &&
                        <p className='error-msg-step1-texteditor'>Availability percentage should be between 0-100</p>
                      }
                    </div>

                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? "inherit" : "none"}` }} className="step2-currency">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: '5px',
                          alignItems: 'center'
                        }}
                      >
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={
                            _get(touched, 'availabilityHoursPerWeek') &&
                            Boolean(_get(errors, 'availabilityHoursPerWeek'))
                          }
                          required
                        >
                          <InputLabel id="demo-simple-select-label">
                            Availability (per week)
                          </InputLabel>
                          <Select
                            value={_get(values, 'availabilityHoursPerWeek')}
                            label="Availability (per week)"
                            onChange={(event) => {
                              setFieldValue('availabilityHoursPerWeek', event.target.value);
                            }}
                            error={
                              touched.availabilityHoursPerWeek &&
                              Boolean(errors.availabilityHoursPerWeek)
                            }
                            helperText={
                              touched.availabilityHoursPerWeek && errors.availabilityHoursPerWeek
                            }
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                          >
                            <MenuItem value={10}>0-10 hours per week </MenuItem>
                            <MenuItem value={20}>10-20 hours per week</MenuItem>
                            <MenuItem value={30}>20-30 hours per week</MenuItem>
                            <MenuItem value={40}>30-40 hours per week</MenuItem>
                          </Select>
                        </FormControl>
                        <div
                          title=" Availability means how many hours per week the candidate should to be available to work on your project."
                          enterTouchDelay={0}
                        >
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </Box>
                    </div>

                    <div className="step2-currency">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Start date"
                          openTo="year"
                          disablePast
                          views={['year', 'month', 'day']}
                          required
                          name="startDate"
                          value={values.startDate}
                          onChange={(newValue) => {
                            setFieldValue('startDate', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onBlur={handleBlur}
                              {...params}
                              error={
                                _get(touched, 'startDate') &&
                                Boolean(_get(errors, 'startDate'))
                              }
                              helperText={
                                touched.startDate && errors.startDate
                              }
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <div style={{ display: `${(props?.newGig?.titleAndSkills?.jobType == "fractional") ? "inherit" : "none"}` }} className="step2-currency">
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          label="Month Start Day"
                          openTo="year"
                          disablePast
                          views={['year', 'month', 'day']}
                          required
                          name="monthStartDate"
                          value={values.monthStartDate}
                          onChange={(newValue) => {
                            setFieldValue('monthStartDate', newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                              onBlur={handleBlur}
                              {...params}
                              error={false}
                              // helperText={""}
                              required
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', gap: '28px' }} className='step2-currency'>

                      {
                        (props?.newGig?.titleAndSkills?.jobType != "recruitment")
                        &&
                        !!values.milestones && values.milestones.length > 0
                        &&
                        values.milestones.map((itm, idx) => (
                          <div key={`milestone-${idx}`} className='milestone-wrapper'>
                            <div className='milestone-header'>
                              <h2>Milestone {idx + 1}</h2>
                              {
                                props?.newGig?.titleAndSkills?.jobType != "fractional"
                                &&
                                (idx > 0
                                  &&
                                  <button onClick={() => {
                                    const updatedMilestones = values.milestones.filter((_, i) => i !== idx);
                                    setFieldValue("milestones", updatedMilestones);
                                  }}>
                                    <img src={DeleteImg} />
                                  </button>)
                              }
                            </div>
                            <div className='milestone-form-wrapper'>
                              <TextField
                                fullWidth
                                name="milestoneTitle"
                                label="Title"
                                value={values.milestones[idx]?.title || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, title: e.target.value }
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones);
                                }}
                                type="text"
                                required
                                placeholder="Enter Title"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={
                                  touched?.milestones?.[idx]?.title
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.title)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.title && errors.milestones?.[idx]?.title
                                }
                              />
                              <TextField
                                name="milestoneDescription"
                                fullWidth
                                value={values.milestones[idx]?.description || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, description: e.target.value } // Update description
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones); // Trigger state update
                                }}
                                label="Description"
                                variant="outlined"
                                multiline
                                rows={4}
                                required={props?.newGig?.titleAndSkills?.jobType != "fractional" ? true : false}
                                error={
                                  touched?.milestones?.[idx]?.description
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.description)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.description && errors?.milestones?.[idx]?.description
                                }
                              />
                              <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                  label="Expected Completion Date"
                                  openTo="year"
                                  disablePast
                                  views={['year', 'month', 'day']}
                                  required
                                  name="deadline"
                                  value={values.milestones[idx].deadline}
                                  onChange={(newValue) => {
                                    const updatedMilestones = values.milestones.map((milestone, i) =>
                                      i === idx
                                        ? { ...milestone, deadline: newValue || (newValue !== "Invalid Date" ? newValue : "") }
                                        : milestone
                                    );
                                    setFieldValue("milestones", updatedMilestones);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      value={values.milestones[idx].deadline}

                                      fullWidth
                                      error={
                                        touched?.milestones?.[idx]?.deadline
                                        &&
                                        Boolean(errors?.milestones?.[idx]?.deadline)
                                      }
                                      helperText={
                                        touched?.milestones?.[idx]?.deadline && errors?.milestones?.[idx]?.deadline
                                      }
                                      required
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              <TextField
                                fullWidth
                                name="milestoneAmount"
                                label="Amount"
                                value={values.milestones[idx].amount || ""}
                                onChange={(e) => {
                                  const updatedMilestones = values.milestones.map((milestone, i) =>
                                    i === idx
                                      ? { ...milestone, amount: e.target.value || (e.target.value !== "Invalid Date" ? e.target.value : "") }
                                      : milestone
                                  );
                                  setFieldValue("milestones", updatedMilestones);
                                }}
                                type="number"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">{currency}</InputAdornment>
                                  )
                                }}
                                required
                                placeholder="0"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={
                                  touched?.milestones?.[idx]?.amount
                                  &&
                                  Boolean(errors?.milestones?.[idx]?.amount)
                                }
                                helperText={
                                  touched?.milestones?.[idx]?.amount && errors?.milestones?.[idx]?.amount
                                }
                              />
                            </div>
                          </div>
                        ))
                      }
                    </div>
                    {
                      (props?.newGig?.titleAndSkills?.jobType === "gig") ? (
                        <Button
                          variant="outlined"
                          className="section-Button-Step3"
                          sx={{ borderColor: '#f99600', width: "200px", marginLeft: "17px", marginTop: "5px" }}
                          onClick={() =>
                            setFieldValue("milestones", [...values.milestones, {
                              title: "",
                              description: "",
                              deadline: "",
                              amount: "",
                              deliverables: []
                            }])
                          }
                        >
                          + Add Milestone
                        </Button>
                      ) :
                        null
                    }

                    <div className="step2-currency">
                      <ButtonContainerStep2>
                        <div>
                          <Button
                            variant="outlined"
                            type="button"
                            className="step3-back-btn"
                            sx={{ width: '100%', height: '45px' }}
                            onClick={activeBackBtn}
                          >
                            Back
                          </Button>
                        </div>
                        <div>
                          <DraftBtnStep2
                            onClick={() => {
                              saveToDraft({ ...formProps, ...props });
                            }}
                          >
                            Save as Draft
                          </DraftBtnStep2>
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            className="primary-Btn-step2"
                            type="submit"
                            sx={{ width: '100%', height: '45px' }}
                          >
                            NEXT
                          </Button>
                        </div>
                      </ButtonContainerStep2>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </PaddedBox>
    </>
    // {/* </div> */ }
  );
};

const mapStateToProps = (state) => {
  return {
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigStep: (details) => dispatch(setNewGigStep(details)),
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);
