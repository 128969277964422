import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import Footer from '../../../../components/footer1/footer';
import { PaddedBox, PaddedFooter } from '../../../app.styles';
import { Button, Link, Paper, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import PlayIcon from './PlayIcon';
import RightArrow from './RightArrowIcon';
import ExportIcon from './ExportIcon';
import TransactionTable from './TransactionTable';
import config from '../../../../config';
import { getTruzoTransaction } from '../../../../services/wallet';

const WalletManagement = (props) => {
  const isTruzoLinked = props?.profile?.basicInfo?.isTruzoLinked;
  const [walletHistory, setWalletHistory] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const stepGuide = [
    {
      content: "See your money guaranteed in escrow",
      image: "/images/wallet-1.png"
    },
    {
      content: "Get paid when job is done",
      image: "/images/wallet-2.png"
    },
    {
      content: "Convert to your preferred currencies",
      image: "/images/wallet-3.png"
    },
    {
      content: "Withdraw to your local bank account",
      image: "/images/wallet-4.png"
    },
  ]

  useEffect(() => {
    if (isTruzoLinked) {
      setIsLoading(true);
      getTruzoTransaction().then(res => {
        setIsLoading(false);
        // console.log("res", res.data.data)
        setWalletHistory(res.data.data)
      }).catch(err => {
        setIsLoading(false);
        console.error(err)
      })
    }
  }, []);
  return (
    <>
      <PaddedBox
        style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}`, marginTop: '90px', minHeight: "580px" }}>
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}>
          <Typography
            sx={{
              fontSize: "24px",
              lineHeight: "32px",
              fontWeight: 400,
              color: "#000000",
            }}>Manage Your Wallet {"<Content copy req.>"}</Typography>
          {/* <Button
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              textTransform: "capitalize"
            }}>
            <PlayIcon />
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: 400,
                color: "#595757",
              }}>Watch Video</Typography>
          </Button> */}
        </Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          mt={4}>
          {
            stepGuide.map((i, index) => (
              <>
                <Stack
                  justifyContent={"center"}
                  sx={{
                    "& img": {
                      height: "64px",
                      width: "100%",
                      objectFit: "contain"
                    }
                  }}>
                  <img src={i.image} alt='circle' />
                  <Typography
                    component={"div"}
                    sx={{
                      mt: 1,
                      fontSize: "18px",
                      lineHeight: "32px",
                      fontWeight: 400,
                      color: "#000000",
                      textAlign: "center"
                    }}>
                    {i.content}
                  </Typography>
                </Stack>
                {
                  stepGuide.length - 1 !== index &&
                  <Box sx={{ width: "50px" }}>
                    <RightArrow />
                  </Box>
                }

              </>
            ))
          }
        </Stack>
        <Box
          sx={{ mt: 4, textAlign: "center" }}>
          <Button
            size='large'
            variant='contained'
            sx={{ background: "#0543AB" }}>Access Your Truzo Wallets</Button>
        </Box>
        <Box
          sx={{
            px: "24px",
            py: "16px",
            mt: 4,
            border: "1px solid #00000033",
            borderRadius: "8px"
          }}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ px: 1 }}>
            <Typography
              sx={{
                fontSize: "24px",
                lineHeight: "32px",
                fontWeight: 400,
                color: "#000000",
              }}>Transaction History</Typography>
            {/* <Button
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                textTransform: "capitalize"
              }}>
              <ExportIcon />
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "32px",
                  fontWeight: 400,
                  color: "#595757"
                }}>Export</Typography>
            </Button> */}
          </Stack>
          <TransactionTable data={walletHistory} isLoading={isLoading} />
        </Box>
        <Box
          sx={{
            mt: 2,
            background: "#F2F2F7",
            py: 0.5,
            borderRadius: "10px",
          }}>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "30px",
              fontWeight: 500,
              color: "#575757",
              textAlign: "center",
            }}>Note: To check all your transaction and balance, access your <Link
              href={config.TRUZO_ACCOUNT}
              target='_blank'
              sx={{ cursor: "pointer" }}>Truzo Account</Link></Typography>
        </Box>
      </PaddedBox>
      <PaddedFooter style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "240px" : "0px"}` }}>
        <Footer />
      </PaddedFooter>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    myProfile: state.profile.myProfile || {},
    error: state.profile.error,
    basicInfo: state.profile.basicInfo,
    saveSuccess: state.profile.saveSuccess,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(WalletManagement);
