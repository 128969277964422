import React, { useEffect } from 'react'
import { Button, Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Step, stepConnectorClasses, StepContent, StepLabel, Stepper, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { approveTruzoContract, createTruzoTransaction, getTruzoPaymentLink } from '../../../services/wallet';
import showVaildationErrors from '../../../utils/errorEvaluation';
import axios from 'axios';
import { toast } from 'react-toastify';

const steps = [
  {
    label: 'Creating transaction',
    description: ``,
  },
  {
    label: 'Approving business contract',
    description: '',
  },
  {
    label: 'Approving engineer contract',
    description: ``,
  },
  {
    label: 'Generating payment link',
    description: ``,
  },
];
function CreateTransactionModal({
  showModal,
  setShowModal,
  milestone,
  gigId,
  businessId,
  engineerId,
  gigData,
  callback,
  setCurrentSelectedMilestone,
  setShowIframeModal,
  setIframeUrl,
  setTransactionId
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [isLoader, setIsLoader] = React.useState(false);
  // console.log("milestone", milestone)

  const handleClose = () => {
    setShowModal(false);
    if (callback) {
      callback();
    }
    setCurrentSelectedMilestone(undefined)
    setTransactionId(undefined);
    setActiveStep(0)
  }
  useEffect(async () => {
    if (milestone?._id && gigId && businessId && engineerId) {
      if (milestone?.transaction?.paymentLink) {
        return;
      }
      const requestData = { milestoneId: milestone?._id, gigId, businessId, engineerId };
      try {
        setIsLoader(true);
        let transactionId;
        if (!milestone?.transaction) {
          const res1 = await createTruzoTransaction(requestData)
          console.log("res1", res1);
          transactionId = res1?.data?.data?.data?._id;
          if (res1?.data?.message) {
            toast.success(res1?.data?.message);
          }
          setTransactionId(transactionId);
        }
        if (milestone?.transaction?.transactionState === "In Approvals" || milestone?.transaction?.transactionState === "Approved") {
          transactionId = milestone?.transaction?._id;
        }

        setActiveStep(1);
        if (!milestone?.transaction?.approvals?.buyer) {
          const newRequestDataForBuyer = { transactionId, actor: "buyer" }
          const res2 = await approveTruzoContract(newRequestDataForBuyer)
          console.log("res2", res2);
          if (res2?.data?.message) {
            toast.success(res2?.data?.message);
          }
        }
        setActiveStep(2);
        if (!milestone?.transaction?.approvals?.seller) {
          const newRequestDataForSeller = { transactionId, actor: "seller" }
          const res3 = await approveTruzoContract(newRequestDataForSeller);
          console.log("res3", res3);
          if (res3?.data?.message) {
            toast.success(res3?.data?.message);
          }
        }
        setActiveStep(3);
        const res4 = await getTruzoPaymentLink(transactionId);
        if (res4.data?.message) {
          toast.success(res4?.data?.message);
        }
        console.log("res4", res4);
        const paymentLink = res4?.data?.data;
        setActiveStep(4);
        setIsLoader(false);
        if (paymentLink) {
          setShowIframeModal(true);
          setIframeUrl(paymentLink);
          setShowModal(false);
          setActiveStep(0);
        }
      } catch (error) {
        console.log("error", error);
        setIsLoader(false);
        if (axios.isAxiosError(error)) {
          showVaildationErrors(error.response.data)
        }
      }
    }
  }, [milestone, gigId, businessId, engineerId])

  return (
    <Dialog
      open={showModal}
      fullWidth
      maxWidth="sm">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          "& .close": {
            width: "56px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <Typography>Transaction</Typography>
        <IconButton
          disableRipple
          onClick={handleClose}
          size='small'>
          <img src={"/images/close.png"} alt='close' className='close' />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{
          '& .MuiStepLabel-iconContainer .Mui-completed': {
            color: 'success.main', // circle color (COMPLETED)
          },
        }}>
        <Stepper activeStep={activeStep} orientation="vertical" color='success'>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={
              //   index === steps.length - 1 ? (
              //     <Typography variant="caption">Last step</Typography>
              //   ) : null
              // }
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Typography>{step.description}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {/* <Button variant='contained' fullWidth color='inherit' sx={{ mt: 2 }}>Try Again</Button> */}
        <Typography
          sx={{
            mt: 2,
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "22.4px",
            textAlign: "left",
            color: "#757575",
          }}>Please wait a moment. You’ll be automatically redirected to the payment screen shortly.</Typography>
      </DialogContent>
      {
        isLoader &&
        <Box sx={{ width: '100%' }}>
          <LinearProgress color='info' />
        </Box>
      }
    </Dialog>
  )
}

export default CreateTransactionModal
