import { useCallback, useEffect, useState } from 'react'
import { gigDetailId } from '../services/gig';

function useGigData(gigId) {
  const [isLoading, setIsLoading] = useState(false);
  const [gigData, setGigData] = useState();

  const fetchData = async () => {
    setIsLoading(true);
    const res = await gigDetailId(gigId);
    setIsLoading(false);
    if (res.data) {
      setGigData(res.data);
    }
  };

  useEffect(() => {
    if (gigId) {
      fetchData();
    }
  }, [gigId]);

  const refresh = useCallback(() => {
    fetchData();
  }, []);

  return { data: gigData, isLoading, refresh }
}

export default useGigData
