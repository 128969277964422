import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CustomRow, PaddedBox } from '../../app.styles';
import {
  Box,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { downloadFileFromLink, downloadPDFWithAxios, formatDate } from '../../../utils/helpers';
import CreateTransactionModal from './CreateTransaction';
import useGigData from '../../../hooks/useGigData';
import IframeModal from './IframeModal';
import { checkTruzoPaymentStatus, downloadContract } from '../../../services/wallet';
import PageLoader from '../../../components/Loaders/PageLoader';
import { milestoneStatus, milestoneStatusSx } from '../../../utils/truzo';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { getUserType } from '../../../utils/user';
import { completeBusinessMilestone, completeEngineerMilestone } from '../../../services/gig';
import useHireData from '../../../hooks/useHireData';

function MilestonePayment(props) {
  const { id: gigId, proposalId } = useParams();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [transactionId, setTransactionId] = useState();
  const [currentSelectedMilestone, setCurrentSelectedMilestone] = useState();
  const [showIframeModal, setShowIframeModal] = useState(false);
  const [iframeUrl, setIframeUrl] = useState();
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const { data: gigData, refresh, isLoading } = useHireData(gigId, proposalId);
  const auth = useSelector((state) => state.auth);
  const handlePayNow = (data) => {
    console.log("[test] milestone data : ", data);
    if (data?.transaction?.paymentStatus === 'In Trust') {
      return;
    }
    if (data?.transaction?.paymentLink) {
      //  checkTruzoPaymentStatus(data?.transaction?._id)
      // console.log("data.paymentLink", data?.transaction?.paymentLink)
      setShowIframeModal(true);
      setIframeUrl(data?.transaction?.paymentLink);
      setTransactionId(data?.transaction?._id);
      return;
    }
    setCurrentSelectedMilestone(data);
    setShowModal(true);
  };

  const gig = gigData?.gig;
  // const milestones = [] ; // gigData?.proposal?.milestones || [];

  const milestones = useMemo(() => {
    const dataToReturn = [];
    if (gigData) {
      gigData.gig.milestones.forEach((element) => {
        const engineerMilestoneData = gigData.proposal.milestones.find(
          (item) => item._id === element._id
        );
        dataToReturn.push({
          ...element,
          title: engineerMilestoneData.title,
          description: engineerMilestoneData.description,
          deadline: engineerMilestoneData.deadline,
          amount: engineerMilestoneData.amount
        });
      });
    }
    return dataToReturn;
  }, [gigData]);

  console.log("[test] milestones : ", milestones)

  const truzoIframeCallback = async () => {
    if (!transactionId) {
      return;
    }
    try {
      await checkTruzoPaymentStatus(transactionId);
    } catch (error) { }
    setCurrentSelectedMilestone(undefined);
    setIframeUrl(undefined);
    setShowModal(false);
    setTransactionId(undefined);
    refresh();
  };

  const handleContractActions = async (type) => {
    try {
      setIsPdfLoading(true);
      if (type === 'view') {
        const res = await downloadContract(gigId);
        downloadFileFromLink(res?.data?.data?.downloadUrl);
      } else if (type === 'download') {
        const res = await downloadContract(gigId);
        downloadPDFWithAxios(res?.data?.data?.downloadUrl);
      }
    } catch (error) {
    } finally {
      setIsPdfLoading(false);
    }
  };

  const markMilestoneCompletedAsEngineer = async (milestoneId, transactionId) => {
    try {
      await completeEngineerMilestone({
        gigId,
        transactionId,
        milestoneId
      });
      window.location.reload();
    } catch (err) {
    } finally {
    }
  };

  const markMilestoneCompletedAsBusiness = async (milestoneId, transactionId) => {
    try {
      await completeBusinessMilestone({
        gigId,
        transactionId,
        milestoneId
      });
      window.location.reload();
    } catch (err) {
    } finally {
    }
  };

  const updateMileStoneStatus = (milestoneData) => {
    if (milestoneData?.transaction?.milestoneStatus === milestoneStatus.STARTED) {
      getUserType() === 'business'
        ? markMilestoneCompletedAsBusiness(
          milestoneData.transaction.milestoneId,
          milestoneData.transaction.transactionId
        )
        : markMilestoneCompletedAsEngineer(
          milestoneData.transaction.milestoneId,
          milestoneData.transaction.transactionId
        );
    }
  };

  const getMilestoneLabelStatus = (milestoneData) => {
    if (getUserType() === 'business') {
      return milestoneData.transaction?.milestoneStatus === milestoneStatus.COMPLETED
        ? 'Completed'
        : 'Mark as Complete';
    }

    return milestoneData.transaction?.isCompletedByEngineer ? 'Completed' : 'Mark as Complete';
  };


  const getDisabledMilestoneLabelStatus = (milestoneData) => {
    if (getUserType() === 'business') {
      return milestoneData.transaction?.milestoneStatus === milestoneStatus.COMPLETED || !milestoneData.transaction?.isCompletedByEngineer
    }

    return Boolean(milestoneData.transaction?.isCompletedByEngineer);
  };

  const getMilestoneLabelStatusTooltip = (milestoneData) => {
    if (getUserType() === 'business') {
      if (milestoneData.transaction?.milestoneStatus === milestoneStatus.COMPLETED) {
        return "You have already marked this milestone as completed.";
      } else if (!milestoneData.transaction?.isCompletedByEngineer) {
        return "The Engineer has not yet completed the work for this milestone.";
      }
    }
    return ""
  };

  return (
    <>
      <PaddedBox
        style={{
          paddingLeft: `${!!auth && !!auth.isDrawerOpen && auth.isDrawerOpen == true ? '240px' : '0px'
            }`
        }}
      >
        <CustomRow container>
          <Typography
            variant="h6"
            component="h6"
            sx={{ fontWeight: 700, textTransform: 'capitalize', fontSize: '22px' }}
          >
            <Button
              variant="outlined"
              className="h-color-button-outlined smallButton"
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </Button>
            <Typography sx={{ ml: 1.4 }} component="span" />
            Milestone Payment Hub
          </Typography>
        </CustomRow>
        <Box
          sx={{
            mt: 3,
            pl: 10,
            pr: 2,
            '& .label': {
              fontSize: '18px',
              fontWeight: 400,
              lineHeight: '20px',
              color: '#1E1E1E',
              minWidth: '150px'
            },
            '& .value': {
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '18px',
              color: '#757575'
            }
          }}
        >
          <Stack flexDirection={'row'} justifyContent={'flex-end'} gap={1}>
            <Stack flexDirection={'row'} gap={2}>
              <Button
                variant="contained"
                sx={{
                  background: '#FFFFFF',
                  '&:hover': {
                    background: '#FFFFFF'
                  }
                }}
                disabled={isPdfLoading}
                onClick={() => {
                  handleContractActions('view');
                }}
              >
                <RemoveRedEyeIcon sx={{ color: '#595757' }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  background: '#FFFFFF',
                  '&:hover': {
                    background: '#FFFFFF'
                  }
                }}
                disabled={isPdfLoading}
                onClick={() => {
                  handleContractActions('download');
                }}
              >
                <FileDownloadIcon sx={{ color: '#595757' }} />
              </Button>
            </Stack>
          </Stack>
          <Typography
            component="div"
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '36px'
            }}
          >
            Gig Overview
          </Typography>
          <Stack flexDirection={'row'} alignItems={'center'} gap={4} sx={{ mt: 2 }}>
            <Typography className="label" component={'div'}>
              Gig Title
            </Typography>
            <Typography className="value" component={'div'}>
              {gig?.titleAndSkills?.projectTitle}
            </Typography>
          </Stack>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mt: 2 }}
          >
            <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
              <Typography className="label" component={'div'}>
                Project Duration
              </Typography>
              <Typography className="value" component={'div'}>
                {gig?.scopeAndBudget?.projectDuration
                  ? `${gig.scopeAndBudget.projectDuration} hours`
                  : 'Not Disclosed'}
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'} gap={4} sx={{ width: '50%' }}>
              <Typography className="label" component={'div'}>
                Area of expertise
              </Typography>
              <Typography className="value" component={'div'}>
                {gig?.fieldOfSpecialization?.reduce(
                  (a, b) => a + b + (gig?.fieldOfSpecialization.length > 1 ? ', ' : ''),
                  ''
                ) || ''}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ mt: 2 }}
          >
            <Stack flexDirection={'row'} alignItems={'center'} gap={4}>
              <Typography className="label" component={'div'}>
                Start Date
              </Typography>
              <Typography className="value" component={'div'}>
                {gig?.scopeAndBudget?.startDate
                  ? formatDate(gig.scopeAndBudget.startDate)
                  : 'Not Disclosed'}
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} alignItems={'center'} gap={4} sx={{ width: '50%' }}>
              <Typography className="label" component={'div'}>
                Budget
              </Typography>
              <Typography className="value" component={'div'}>
                {gig?.scopeAndBudget?.currency || 'R'} {gig?.scopeAndBudget?.budget || ''}
              </Typography>
            </Stack>
          </Stack>
          <Box
            sx={{
              border: '1px solid #D5E0D5',
              my: 4
            }}
          />
          <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '36px',
              color: '#1E1E1E',
              mt: 1
            }}
          >
            Milestone Details
          </Typography>
          <TableContainer>
            <Table
              sx={{
                mt: 4,
                '& td': { textAlign: 'center' }
              }}
            >
              <TableHead
                sx={{
                  background: '#F5F6FA',
                  '& td, & th': { border: 0, textAlign: 'center' },
                  borderRadius: '5px'
                }}
              >
                <TableRow>
                  <TableCell>Milestone</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Amount (R)</TableCell>
                  <TableCell>Expected Completion Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <PageLoader
                        sx={{
                          textAlign: 'center',
                          minHeight: '200px'
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ) : (
                  milestones.map((row, index) => (
                    <TableRow key={row.id} sx={{ '& td, & th': { border: 0 } }}>
                      <TableCell>Milestone {index + 1}</TableCell>
                      <TableCell>{row?.title || '-'}</TableCell>
                      <TableCell sx={{ color: row.amount > 0 ? '#34C759' : '#FF3B30' }}>
                        {row.amount ? row.amount : '-'}
                      </TableCell>
                      <TableCell>{formatDate(row.deadline)}</TableCell>
                      <TableCell>
                        <span
                          style={{
                            padding: '6px 12px',
                            borderRadius: '16px',
                            ...milestoneStatusSx(
                              row?.transaction?.milestoneStatus || milestoneStatus.NOT_STARTED
                            )
                          }}
                        >
                          {/* {truzoPaymentStatus?.[row?.transaction?.paymentStatus]} */}
                          {row?.transaction?.milestoneStatus || milestoneStatus.NOT_STARTED}
                        </span>
                      </TableCell>
                      <TableCell>
                        <Stack gap={1} alignItems={'center'}>
                          {/* <Button
                            variant='contained'
                            size='small'
                            sx={{ background: "#0140AA", textTransform: "none", width: "150px" }}
                            onClick={() => handlePayNow(row)}>Create Transaction</Button> */}
                          {getUserType() === 'business' && (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{ background: '#0140AA', textTransform: 'none', width: '100px' }}
                              disabled={row?.transaction?.paymentStatus === 'In Trust'}
                              onClick={() => {
                                handlePayNow(row);
                              }}
                            >
                              Pay Now
                            </Button>
                          )}
                          {row?.transaction?.milestoneStatus &&
                            row?.transaction?.milestoneStatus !== milestoneStatus.NOT_STARTED && (
                              <Tooltip
                                arrow
                                title={getMilestoneLabelStatusTooltip(row)}>
                                <Button
                                  size="small"
                                  variant="contained"
                                  onClick={() => {
                                    updateMileStoneStatus(row);
                                  }}
                                  sx={{
                                    textTransform: 'none',
                                    background:
                                      row?.transaction?.milestoneStatus === milestoneStatus.COMPLETED
                                        ? '#048F27'
                                        : '#F99600',
                                    "&.Mui-disabled": {
                                      pointerEvents: "auto"
                                    }
                                  }}
                                  disabled={getDisabledMilestoneLabelStatus(row)}
                                >
                                  <TaskAltIcon fontSize="small" sx={{ mr: '2px' }} />
                                  {getMilestoneLabelStatus(row)}
                                </Button>
                              </Tooltip>
                            )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </PaddedBox>
      <CreateTransactionModal
        milestone={currentSelectedMilestone}
        showModal={showModal}
        setShowModal={setShowModal}
        gigId={gigId}
        businessId={gigData?.bussinessId}
        engineerId={gigData?.userId}
        gigData={gig}
        callback={refresh}
        setCurrentSelectedMilestone={setCurrentSelectedMilestone}
        setIframeUrl={setIframeUrl}
        setShowIframeModal={setShowIframeModal}
        setTransactionId={setTransactionId}
      // businessId={profile?.basicInfo?.userId}
      />
      <IframeModal
        iframeUrl={iframeUrl}
        open={showIframeModal}
        setOpen={setShowIframeModal}
        callback={truzoIframeCallback}
      />
    </>
  );
}

export default MilestonePayment;
