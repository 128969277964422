import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import _toNumber from 'lodash/toNumber';
import { PaddedBox, PaddedFooter } from '../../app.styles';
import { Button, Grid } from '@mui/material';
import GridTable from '../../../components/GridTable/GridTable';
import { getQueryParams } from '../../../utils/helpers';
import { getGigs } from '../../../services/gig';
import Typography from '@mui/material/Box';
import { connect } from 'react-redux';
import '../gigDashboard/GigDashboard.css';
import Footer from '../../../components/footer1/footer';

const ActivePosts = (props) => {
  const query = getQueryParams();
  const queryPage = query.page;
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(queryPage || 1);
  const [paginationVal, setPaginationVal] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const createData = (name, duration, rate, hours, location, id, dropdown) => {
    if (rate === '') {
      rate = 'Not Disclosed';
    }
    return [name, duration, rate, hours, location, id, dropdown];
  };

  const setData = (data) => {
    // console.log("data", data)
    const rowData = [];
    const gigList = data?.gigList || [];
    const paginationObject = data?.paginationResult || {};
    for (let i = 0; i < gigList.length; i++) {
      const item = gigList[i];
      const name = item?.titleAndSkills.projectTitle || '';
      const rate = item?.scopeAndBudget?.hourlyRate
        ? `${item?.scopeAndBudget?.currency || 'R'} ${item?.scopeAndBudget?.hourlyRate} /hr`
        : '';
      const hours = item?.scopeAndBudget?.availabilityHoursPerWeek
        ? `${item?.scopeAndBudget?.availabilityHoursPerWeek} hrs/week`
        : '';
      const location = item?.deliverablesAndLocation?.location?.country
        ? item?.deliverablesAndLocation?.location?.country
        : 'Remote';
      const id = item?._id || '';

      const applicantName = item?.engineerName;
      const engineerId = item?.engineerId;

      rowData.push(
        createData(
          <span onClick={() => history.push(`/gig/active/${id}`)}>{name}</span>,
          <span
            onClick={() => {
              const redirectUrl = `/gig/active-post`;
              history.push(
                `/engineer/details/${engineerId}?redirectUrl=${encodeURIComponent(redirectUrl)}`
              );
            }}
          >
            {applicantName}
          </span>,
          rate,
          hours,
          location,
          <Button
            sx={{
              textTransform: 'none'
            }}
            variant="text"
            onClick={() => {
              console.log('applicantData 2', item);
              history.push(`/gig/active-posts/milestone-payment/${item._id}/${item.proposalId}`)
            }}
          >
            View Details
          </Button>
        )
      );
    }
    setRows(rowData);
    setPaginationVal(paginationObject.totalPages || 1);
  };

  const fetchData = (currentPage) => {
    const obj = {
      paginationInput: {
        pageNumber: _toNumber(currentPage),
        pageSize: 10
      },
      status: ['ACTIVE_POSTS']
    };
    setIsLoading(true);
    getGigs(obj, setData)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(page);
  }, [paginationVal]);

  const handleChange = (event, value) => {
    fetchData(value);
    setPage(value);
  };

  const redirectToPostNewGig = () => {
    history.push('/gig/new');
  };

  const headers = ['GIG NAME', 'APPLICANT NAME', 'AVAILABILITY', 'RATE', 'LOCATION', 'ACTIONS'];

  return (
    <>
      <PaddedBox
        style={{
          textAlign: 'center',
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`,
          minHeight: '580px'
        }}
      >
        <Grid container>
          <Typography className="font-fix" variant="h6" component="h6" color="pColor.main">
            Active Gigs
          </Typography>
          <Grid container className="table-Padding">
            <GridTable
              isLoading={isLoading}
              headers={headers}
              rows={rows}
              // handleRowClick={handleRowClick}
              handleChange={handleChange}
              pagination={paginationVal}
              defaultPage={_toNumber(page)}
              emptyMessage="You don’t have any Active Gigs, would you like to create one?"
              emptyBoxButtonText="Post a Gig"
              onEmptyBoxButtonClick={redirectToPostNewGig}
            ></GridTable>
          </Grid>
        </Grid>
      </PaddedBox>
      <PaddedFooter
        style={{
          paddingLeft: `${
            !!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
              ? '240px'
              : '0px'
          }`
        }}
      >
        <Footer />
      </PaddedFooter>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

export default connect(mapStateToProps)(ActivePosts);
