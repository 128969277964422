import React, { useMemo } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'

function CustomModal({ showModal,
  setShowModal,
  type,
  title = "Title",
  content = "Body text for whatever you’d like to say. Add main takeaway points, quotes, anecdotes, or even a very very short story.",
  onSubmit }) {
  const getIcon = useMemo(() => {
    switch (type) {
      case "success":
        return "/images/success.png";
      case "error":
        return "/images/error.png";
      case "info":
        return "/images/info.png";
      case "warning":
        return "/images/warning.png";
      default:
        return "/images/info.png";
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  }

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}>
      <DialogTitle
        sx={{
          textAlign: "center",
          "& img": {
            width: "48px",
            height: "100%",
            objectFit: "contain"
          }
        }}>
        <img src={getIcon} alt='icon' />
        <Typography sx={{
          textAlign: "center",
          fontSize: "24px",
          fontWeight: 600,
          lineHeight: "28px",
          color: "#1E1E1E"
        }}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "24px",
            color: "#757575",
          }}>
          {content}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='error' onClick={handleClose}>Close</Button>
        <Button variant='contained' color={type} onClick={()=>onSubmit && onSubmit()}>Submit</Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomModal
