import React from "react";
import { Dialog, DialogContent, DialogTitle, Button, IconButton } from "@mui/material";

function IframeModal({ iframeUrl, open, setOpen, callback }) {

  const handleClose = () => {
    setOpen(false);
    if (callback) {
      callback();
    }
  };

  return (
    <div>

      {/* Modal */}
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth
        aria-labelledby="iframe-dialog-title"
      >
        <DialogTitle id="iframe-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            "& .close": {
              width: "56px",
              height: "100%",
              objectFit: "contain"
            }
          }}>
          <IconButton
            disableRipple
            onClick={handleClose}
            size='small'>
            <img src={"/images/close.png"} alt='close' className='close' />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <iframe
            src={iframeUrl}
            title="Iframe Content"
            style={{
              width: "100%",
              height: "600px",
              border: "none",
              overflowY: "auto"
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default IframeModal;
