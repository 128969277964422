import { isRegisteredUserRoute } from './authcheck';
import Engineer from '../containers/profile/engineer/engineer/Engineer';
import Education from '../containers/profile/engineer/education/Education';
import MyProfile from '../containers/profile/engineer/myprofile/MyProfile';
import Employment from '../containers/profile/engineer/employment/Employment';
import Representative from '../containers/profile/owner/representative/Representative';
import CompanyDetails from '../containers/profile/owner/companydetails/CompanyDetails';
import Discover from '../containers/gigUser/discover/Discover';
import Applied from '../containers/gigUser/applied/Applied';
import Applicants from '../containers/gigUser/applicants/Applicants';
import Saved from '../containers/gigUser/saved/Saved';
import GigOwnerDashboard from '../containers/gigOwner/gigDashboard/GigDashboard';
import NewGig from '../containers/gigOwner/composeGig/NewGig';
import EditGig from '../containers/gigOwner/composeGig/EditGig';
import DraftGigs from '../containers/gigOwner/DraftGig/DraftGig';
import MyHires from '../containers/gigOwner/Myhires/MyHires';
import CurrentHires from '../containers/gigOwner/Myhires/CurrentHires';
import PastHires from '../containers/gigOwner/Myhires/PastHires';
import GigAppliedEngineer from '../containers/profile/engineer/GigAppliedProfile/GigAppliedEngineer';
import ActiveGigs from '../containers/gigOwner/activegigs/ActiveGigs';
import ClosedGigs from '../containers/gigOwner/closedgigs/ClosedGigs';
import InActiveGigs from '../containers/gigOwner/inactivegigs/InActiveGigs';
import EngineersList from '../containers/gigUser/EngineersList/EngineersList';
import Chat from '../containers/chat/Chat';
import RequestQuery from '../containers/query/RequestQuery';
import EngineerDashboard from '../containers/gigUser/EngineerDashboard/EngineerDashboard';
import ActiveEngineer from '../containers/gigUser/ActiveEngineer/ActiveEngineer';
import CompleteGigs from '../containers/gigUser/CompleteGigs/CompleteGigs';
import ResetPasswordCom from '../containers/query/ResetPasswordCom';
import GigDetailsP from '../containers/gigUser/gigdetails/GigDetailsP';
import InvoiceSummary from './../containers/invoices/summary/index';
import InvoicePaid from './../containers/invoices/paid/index';
import InvoicePending from './../containers/invoices/pending/index';
import InvoiceRejected from './../containers/invoices/rejected/index';
import InvoiceDetails from './../containers/invoices/details/index';
import proposalDetails from '../containers/gigUser/proposalDetails';
import ReviewGig from '../containers/reviews/businessSide/index';
import ReviewGigByEngineer from '../containers/reviews/engineerSide/index';
import ViewFeedback from '../containers/reviews/businessSide/ViewFeedback';
import ViewFeedbackEng from '../containers/reviews/engineerSide/ViewFeedbackEng';
import ViewSubmittedFeedback from '../containers/reviews/businessSide/ViewSubmittedFeedback';
import ViewSubmittedFeedbackEng from '../containers/reviews/engineerSide/ViewSubmittedFeedbackEng';
import bankDetails from '../containers/profile/engineer/BankDetails/bankDetails';
import viewBankDetails from '../containers/profile/engineer/BankDetails/viewBankDetails';
import WalletManagement from '../containers/profile/engineer/WalletManagement';
import TruzoTransactionFailed from '../containers/profile/engineer/WalletManagement/TruzoTransactionFailed';
import { getUserType } from '../utils/user';
import ActivePosts from '../containers/gigOwner/activeposts/ActivePosts';
import MilestonePayment from '../containers/gigOwner/milestone-payment/MilestonePayment';

const privateRoutes = [
  {
    path: '/engineerquery',
    component: isRegisteredUserRoute(RequestQuery, ['engineer'], 'engineerquery'),
    exact: true
  },
  {
    path: '/bussinessquery',
    component: isRegisteredUserRoute(RequestQuery, ['business'], 'bussinessquery'),
    exact: true
  },
  {
    path: '/engineerpasswordreset',
    component: isRegisteredUserRoute(ResetPasswordCom, ['engineer'], 'engineerpasswordreset'),
    exact: true
  },
  {
    path: '/bussinesspasswordreset',
    component: isRegisteredUserRoute(ResetPasswordCom, ['business'], 'bussinesspasswordreset'),
    exact: true
  },
  {
    path: '/echat',
    component: isRegisteredUserRoute(Chat, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/ochat',
    component: isRegisteredUserRoute(Chat, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/engineer',
    component: isRegisteredUserRoute(Engineer, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/myProfile',
    component: isRegisteredUserRoute(MyProfile, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/education',
    component: isRegisteredUserRoute(Education, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/bankdetails',
    component: isRegisteredUserRoute(bankDetails, ['engineer', 'business'], getUserType() === "engineer" ? 'engProfile' : 'ownProfile'),
    exact: true
  },
  {
    path: '/wallet-management',
    component: isRegisteredUserRoute(WalletManagement, ['engineer', 'business'], getUserType() === "engineer" ? 'engDashboard' : 'ownerDashboard'),
    exact: true
  },
  {
    path: '/truzo-status',
    component: isRegisteredUserRoute(TruzoTransactionFailed, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/viewBankDetails',
    component: isRegisteredUserRoute(viewBankDetails, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/employment',
    component: isRegisteredUserRoute(Employment, ['engineer'], 'engProfile'),
    exact: true
  },
  {
    path: '/representative',
    component: isRegisteredUserRoute(Representative, ['business'], 'ownProfile'),
    exact: true
  },
  {
    path: '/companydetails',
    component: isRegisteredUserRoute(CompanyDetails, ['business'], 'ownProfile'),
    exact: true
  },
  {
    path: '/gig/dashboard',
    component: isRegisteredUserRoute(GigOwnerDashboard, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/dashboard',
    component: isRegisteredUserRoute(EngineerDashboard, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/discover',
    component: isRegisteredUserRoute(Discover, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/discover/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/applied/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/active/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/complete/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/saved/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/applied',
    component: isRegisteredUserRoute(Applied, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/applied/proposal/:id',
    component: isRegisteredUserRoute(proposalDetails, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/active',
    component: isRegisteredUserRoute(ActiveEngineer, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/complete',
    component: isRegisteredUserRoute(CompleteGigs, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/saved',
    component: isRegisteredUserRoute(Saved, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/gig/new',
    component: isRegisteredUserRoute(NewGig, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/edit/:id',
    component: isRegisteredUserRoute(EditGig, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/draft',
    component: isRegisteredUserRoute(DraftGigs, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/hires',
    component: isRegisteredUserRoute(MyHires, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/hires/currenthires/',
    component: isRegisteredUserRoute(CurrentHires, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/hires/pasthires/',
    component: isRegisteredUserRoute(PastHires, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active',
    component: isRegisteredUserRoute(ActiveGigs, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active-post',
    component: isRegisteredUserRoute(ActivePosts, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/milestone-payment/:id',
    component: isRegisteredUserRoute(MilestonePayment, ['business', "engineer"], getUserType() === "engineer" ? 'engDashboard' : 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active-posts/milestone-payment/:id/:proposalId',
    component: isRegisteredUserRoute(MilestonePayment, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/closed',
    component: isRegisteredUserRoute(ClosedGigs, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/inactive',
    component: isRegisteredUserRoute(InActiveGigs, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active/applicants/:id',
    component: isRegisteredUserRoute(Applicants, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/inact/applicants/:id',
    component: isRegisteredUserRoute(Applicants, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/closed/applicants/:id',
    component: isRegisteredUserRoute(Applicants, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active/proposal/:id',
    component: isRegisteredUserRoute(proposalDetails, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/active/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/hires/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/hires/pasthires/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/hires/currenthires/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/inact/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/closed/:id',
    component: isRegisteredUserRoute(GigDetailsP, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/engineer/list',
    component: isRegisteredUserRoute(EngineersList, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/engineer/details/:id',
    component: isRegisteredUserRoute(GigAppliedEngineer, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/review/business/add/:id',
    component: isRegisteredUserRoute(ReviewGig, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/review/business/viewFeedback/:id',
    component: isRegisteredUserRoute(ViewFeedback, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/gig/review/business/viewSubmittedFeedback/:id',
    component: isRegisteredUserRoute(ViewSubmittedFeedback, ['business'], 'ownerDashboard'),
    exact: true
  },

  // Invoice Routes:

  // Engineer:
  {
    path: '/engineer/invoices/summary',
    component: isRegisteredUserRoute(InvoiceSummary, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/engineer/invoices/paid',
    component: isRegisteredUserRoute(InvoicePaid, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/engineer/invoices/pending',
    component: isRegisteredUserRoute(InvoicePending, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/engineer/invoices/rejected',
    component: isRegisteredUserRoute(InvoiceRejected, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/engineer/invoices/:id',
    component: isRegisteredUserRoute(InvoiceDetails, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/gig/review/engineer/add/:id',
    component: isRegisteredUserRoute(ReviewGigByEngineer, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/gig/review/engineer/viewFeedback/:id',
    component: isRegisteredUserRoute(ViewFeedbackEng, ['engineer'], 'engDashboard'),
    exact: true
  },
  {
    path: '/gig/review/engineer/viewSubmittedFeedback/:id',
    component: isRegisteredUserRoute(ViewSubmittedFeedbackEng, ['engineer'], 'engDashboard'),
    exact: true
  },

  // Business:
  {
    path: '/business/invoices/summary',
    component: isRegisteredUserRoute(InvoiceSummary, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/business/invoices/paid',
    component: isRegisteredUserRoute(InvoicePaid, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/business/invoices/pending',
    component: isRegisteredUserRoute(InvoicePending, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/business/invoices/rejected',
    component: isRegisteredUserRoute(InvoiceRejected, ['business'], 'ownerDashboard'),
    exact: true
  },
  {
    path: '/business/invoices/:id',
    component: isRegisteredUserRoute(InvoiceDetails, ['business'], 'ownerDashboard'),
    exact: true
  },
];

export default privateRoutes;
