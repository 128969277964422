const truzoPaymentStatus = {
  "Released": "Paid",
  "In Trust": "Paid",
  "Pending": "Pending"
}

const truzoPaymentStatusSx = (status) => {
  return {
    backgroundColor: status === truzoPaymentStatus.Released ? '#34C7591A' : status === truzoPaymentStatus["In Trust"] ? "#F996004D" : '#FF3B301A',
    color: status === truzoPaymentStatus.Released ? '#34C759' : status === truzoPaymentStatus["In Trust"] ? "#FF8008" : '#FF3B30',
  }
}

const milestoneStatus = {
  STARTED: 'Started',
  NOT_STARTED: 'NotStarted',
  IN_APPROVALS: 'InApprovals',
  COMPLETED: 'Completed',
}

const milestoneStatusSx = (status) => {
  let backgroundColor;
  let color;
  switch (status) {
    case milestoneStatus.COMPLETED:
      backgroundColor = '#34C7591A';
      color = '#34C759';
      break;
    case milestoneStatus.IN_APPROVALS:
    case milestoneStatus.STARTED:
      backgroundColor = "#F996004D";
      color = "#FF8008";
      break;
    default:
      backgroundColor = '#FF3B301A';
      color = '#FF3B30';
      break;
  }
  return {
    backgroundColor,
    color
  }
}


export {
  truzoPaymentStatus,
  truzoPaymentStatusSx,
  milestoneStatus,
  milestoneStatusSx
}
