import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Avatar,
  Chip,
  TextField,
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import Reportlogo from '../../../assests/report.png';
import { useLocation } from 'react-router-dom';
import { getGigLocation, formatDate, getDurationUnit, getDurationLabel, getJobType, checkIfVerified } from '../../../utils/helpers';
import { getGigsById, getAllFeedbackApi } from '../../../services/gig';
import * as GigAllActions from '../../../store/actions/gig';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import PinDropIcon from '@mui/icons-material/PinDrop';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Applied from '../../../assests/check_circle.png';
import { styled } from '@mui/material/styles';
import './gigDetail.css';
import { getUserType, getTokenDetails } from '../../../utils/user';
import Dummypic from '../../../assests/2.jpg';
import { applyToGigApi } from '../../../services/engineerGig';
import { getProfileDetApi } from '../../../services/profile';
import { getGigAllApplicants } from '../../../services/gig';
import Pagination from '@mui/material/Pagination';
import { getUploadApi } from '../../../services/upload';
import Boundingbox from '../../../assests/attach_file.png';
import days from '../../../assests/days.jpg';
import face from '../../../assests/face.png';
import HourlyRate from '../../../assests/HourlyRate.png';
import Areaofexpertise from '../../../assests/Areaofexpertise.png';
import Duration from '../../../assests/Duration.png';
import Budget from '../../../assests/Budget.png';
import RemoteJob from '../../../assests/RemoteJob.png';
import StartDate from '../../../assests/StartDate.png';
import StatusRightIcon from '../../../assests/StatusRightIcon.svg';
import InactiveStatus from '../../../assests/InactiveStatus.svg';
import CloseStatus from '../../../assests/CloseStatus.svg';
import DraftStatus from '../../../assests/DraftStatus.svg';
import Hours from '../../../assests/Hours.svg';
import Days from '../../../assests/Days.svg';
import Weeks from '../../../assests/Weeks.svg';
import Months from '../../../assests/Months.svg';
import CoverIcon from '../../../assests/CoverIcon.svg';
import { DragDropIcon } from '../../profile/engineer/DragDrop/DragDrop';
import RichTextEditor from 'react-rte';
import { BackBtn, GiglinkInput, NoLongerApctImg } from '../../app.styles';
import './gigDetailsAttributes.scss';
import ReactStars from "react-rating-stars-component";

const ReadMore = ({ children }) => {
  let text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="gig-detail-see-more">
      {isReadMore ? (text.length > 385 ? text.slice(0, 635) + '.....' : text) : text}
      <br></br>
      {text.length >= 385 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? 'See more' : 'See less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const GigDetails_Attributes = (props) => {
  const [job, setList] = useState([]);
  const [userType, setUserType] = React.useState(getUserType());
  const [allgigAplicants, setAllgigAplicants] = useState()
  const [applyStatus, setApplyStatus] = useState()
  const [profileInfo, setProfileInfo] = useState()
  const [coverLetterOpen, setCoverLetterOpen] = useState(false)
  const [coverletterText, setCoverletterText] = useState("")
  const [attachmentFile, setAttachmentFile] = useState()
  const [attachmentFileName, setAttachmentFileName] = useState("")
  const [coverTextError, setCoverTextError] = useState("");
  const [loader, setLoader] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [readMoreDetails, setreadMoreDetails] = useState(false)
  const [feedbacks, setfeedbacks] = useState([]);
  const [feedbackPaginationData, setfeedbackPaginationData] = useState({
    "currentPage": 0,
    "pageSize": 5,
    "totalPages": null,
    "totalRecords": null,
  });

  const [referralsList, setreferralsList] = useState([])

  const [imgUploadedtext, setImgUploadedtext] = useState(true);
  const { id } = useParams();
  const history = useHistory();
  const tokenDetails = getTokenDetails();

  const setData = (data) => {
    setList(data);
  };

  const getAllFeedbackParams = (gigd) => {
    return {
      // paginationInput: {
      //   pageNumber: feedbackPaginationData.currentPage + 1,
      //   pageSize: feedbackPaginationData.pageSize
      // },
      recieverId: gigd.gig.userId
    }
  }

  const getAllFeedbackParamsPagination = (gigd, val) => {
    return {
      // paginationInput: {
      //   pageNumber: val,
      //   pageSize: feedbackPaginationData.pageSize
      // },
      recieverId: gigd.gig.userId
    }
  }

  const setDataAllFeedback = (data) => {
    setfeedbacks(data.referralsList);
    setfeedbackPaginationData({
      "currentPage": data.paginationResult.currentPage,
      "pageSize": data.paginationResult.pageSize,
      "totalPages": data.paginationResult.totalPages,
      "totalRecords": data.paginationResult.totalRecords
    })
    // console.log(data);
  }
  const handleChange = async (event, value) => {
    const gigd = await getGigsById(id)
    await getAllFeedbackApi(
      getAllFeedbackParamsPagination(gigd, value),
    ).then((e) => {
      // setfeedbackPaginationData(e.data.paginationResult)
      setreferralsList(e.data.referralsList);
    }).catch((err) => { console.error(err) });
  };
  const fetchData = async () => {
    const gigd = await getGigsById(id);
    setData(gigd);
    await getAllFeedbackApi(
      getAllFeedbackParams(gigd),
      // setDataAllFeedback,
      // props.getAllFeedback,
    ).then((e) => {
      // setfeedbackPaginationData(e.data.paginationResult)
      setreferralsList(e.data.referralsList);
    }).catch((err) => { console.error(err) });
  };

  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (userType === 'engineer') {
      getProfileDetApi(setProfileInfo);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (userType === 'engineer') {
      const obj = {
        gigId: id,
        userId: profileInfo?.basicInfo?.userId
      };
      if (profileInfo?.basicInfo?.userId) {
        getGigAllApplicants(obj, setAllgigAplicants);
      }
    }

  }, [profileInfo])

  useEffect(() => {
    if (applyStatus) {
      window.location.reload();
    }
  }, [applyStatus]);

  const handleFileUploaded = async (file, event) => {
    setLoader(true)
    setImgUploadedtext(false)
    try {
      getUploadApi(file)
        .then((e) => {
          setAttachmentFile(e.data.fileUrl);
          setAttachmentFileName(file.name);
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error uploading file. Please try again!');
        });
    } catch (err) {
      console.error(err);
      toast.error('Error uploading file. Please try again!');
    }
  }

  const applygig = async (id, title) => {
    const objj = {
      gigId: id,
      coverLetter: coverletterText,
      attachment: attachmentFile
    };
    const gigApply = await applyToGigApi(objj, setApplyStatus);
    if (gigApply.status == 200) {
      socket.emit('postNotification', {
        "notificationDescription": `You have successfully applied for ${title}`,
        "userRole": ["engineer"],
        "engineerId": props.profile.basicInfo.userId,
        "redirectUrl": "/applied",
        "isRead": false,
        "engineerProfilePic": props.profile.myProfile.profilePic,
      });
    }
  }

  useEffect(() => {
    if (applyStatus) {
      window.location.reload();
    }
  }, [applyStatus]);

  const getSkillsList = (skills) => {
    return Array.prototype.map.call(skills, (s) => ` ${s.engineerSkill}`).toString();
  };

  const getExpertiseList = (expertise) => {
    return Array.prototype.map.call(expertise, (s) => ` ${s}`).toString();
  };

  const downloadAttachment = (file) => {
    window.open(file);
  };

  function CopytoClick() {
    var copyText = document.getElementById('CopytoClickid');
    if (copyText) {
      navigator.clipboard.writeText(copyText.value);
    }
  }

  const fileInputRef = useRef(null);

  function getCount(str) {
    return str.split(' ').filter(function (num) {
      return num != ''
    }).length;
  }

  const toolBarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" }
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" }
    ]
  };

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createValueFromString("", "html")
  );

  const [hasChanged, sethasChanged] = useState(false);

  /**
   * Handle editor's change.
   * @param {import('react-rte').EditorValue} value Value from the editor.
   */
  function onChange(value) {
    // console.log(value.toString("html"));
    setEditorValue(value);
    setCoverletterText(value.toString("html"))
  }

  console.log('job', job);

  return (
    <div className="gig-details-main-container">
      <div className="Profile-Container-gig-detail">
        <Avatar
          src={_get(job?.companyDetails, 'businessProfileData.companyDetails.profilePic', '')}
          sx={{
            width: '100px',
            height: '100px'
          }}
        ></Avatar>

        <div className="Profile-Name-gig-detail">
          {' '}
          {_get(job?.companyDetails, 'businessProfileData.companyDetails.companyName', '')}{' '}
        </div>
      </div>

      <div className="Gig-link-and-FieldContainer">
        <div className="Field-Container-gig-details">
          <div className="FieldOf-SpecializationText">
            {!!_get(job?.gig, 'titleAndSkills.projectTitle', '') && (
              <ReadMore>{_get(job?.gig, 'titleAndSkills.projectTitle', '')}</ReadMore>
            )}
          </div>
          <div className="FieldOf-JobType">
            Job Type : {getJobType(job?.gig, 'titleAndSkills.jobType', 'Not Disclosed')}
          </div>
          <div className="Created-AtText">
            Posted on :{' '}
            {_get(job?.gig, 'updatedAt') ? formatDate(_get(job?.gig, 'updatedAt', '')) : '-'}
          </div>
        </div>

        {userType === 'admin' && (
          <div className="detail-admin-gigs">
            <div
              className={`status-gig-admin-details ${_get(job?.gig, 'status', '') === 'ACTIVE'
                ? 'status-active'
                : _get(job?.gig, 'status', '') === 'INACTIVE'
                  ? 'status-inactive'
                  : _get(job?.gig, 'status', '') === 'CLOSED'
                    ? 'status-closed'
                    : _get(job?.gig, 'status', '') === 'DRAFT'
                      ? 'status-draft'
                      : ''
                }`}
            >
              {_get(job?.gig, 'status', '') === 'ACTIVE' ? (
                <img
                  sx={{ color: '#0000FF' }}
                  src={StatusRightIcon}
                  alt="StatusRightIcon"
                  fontSize="medium"
                  width="21%"
                />
              ) : _get(job?.gig, 'status', '') === 'INACTIVE' ? (
                <img
                  sx={{ color: '#0000FF' }}
                  src={InactiveStatus}
                  alt="InactiveStatus"
                  fontSize="medium"
                  width="20%"
                />
              ) : _get(job?.gig, 'status', '') === 'CLOSED' ? (
                <img
                  sx={{ color: '#0000FF' }}
                  src={CloseStatus}
                  alt="CloseStatus"
                  fontSize="medium"
                  width="21%"
                />
              ) : _get(job?.gig, 'status', '') === 'DRAFT' ? (
                <img
                  sx={{ color: '#0000FF' }}
                  src={DraftStatus}
                  alt="DraftStatus"
                  fontSize="medium"
                  width="21%"
                />
              ) : (
                ''
              )}
              <div className="sta-green-text">
                {_get(job?.gig, 'status', '') === 'ACTIVE'
                  ? 'Active'
                  : _get(job?.gig, 'status', '') === 'INACTIVE'
                    ? 'Inactive'
                    : _get(job?.gig, 'status', '') === 'CLOSED'
                      ? 'Closed'
                      : _get(job?.gig, 'status', '') === 'DRAFT'
                        ? 'Draft'
                        : ''}
              </div>
            </div>
          </div>
        )}

        {userType === 'business' && _get(job?.gig, 'status') === 'ACTIVE' ? (
          <div className="Gig-link-gig-details">
            <div className="Gig-link-Text">Gig Link</div>
            <GiglinkInput
              value={`${window.location.origin}/gig/public/${id}`}
              id="CopytoClickid"
              title={`${window.location.origin}/gig/public/${id}`}
              disabled
            />
            <div className="Copy-Gig-link-Btn" onClick={CopytoClick}>
              <Button className='copy-btn-gig-details'>
                Copy Link
              </Button>

            </div>
          </div>
        ) : userType === 'engineer' &&
          allgigAplicants?.engineerList?.[0]?.applicationDetails?.status === 'APPLIED' ? (
          <div className="Applied-Box">
            <div className="Applied-Text">Applied</div>
            <img src={Applied} sx={{ width: '25px', height: '25px' }} />
          </div>
        ) : userType === 'engineer' && _get(job?.gig, 'hireCount') >= 1 ? (
          <div className="No-Longer-Apct">
            <NoLongerApctImg>
              {' '}
              <img src={Reportlogo} />{' '}
            </NoLongerApctImg>
            <div className="No-Longer-Apct-Text">No longer considering applications</div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="break-line-gig-details"></div>

      <div className="Project-Detail-Container">
        <div className="PD-title-Text">Project Details</div>
        <Box sx={{ fontSize: '15.5px', color: 'black' }}>
          {!!_get(job?.gig, 'titleAndSkills.projectDetails', '') && (
            <div
              style={{ height: `${readMoreDetails ? 'fit-content' : '200px'}`, overflow: 'hidden', }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: _get(job?.gig, 'titleAndSkills.projectDetails', '')
                }}
              ></div>
            </div>
          )}

          {!!_get(job?.gig, 'titleAndSkills.projectDetails', '') &&
            _get(job?.gig, 'titleAndSkills.projectDetails', '').length > 353 && (
              <button
                className="project-title-see-more-btn"
                onClick={() => {
                  setreadMoreDetails(!readMoreDetails);
                }}
              >{`${readMoreDetails ? 'See Less' : 'See More'}`}</button>
            )}
        </Box>
      </div>

      <div className="break-line-gig-details"></div>

      <div className="Skills-Container-gig-details">
        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Location.svg"
              width="8.2%"
            />
            <div className="Skills-Required-title-Text">Location</div>
          </div>
          <div className="Skills-Required-Location">
            {_get(job?.gig, 'deliverablesAndLocation.location.country')
              ? _get(job?.gig, 'deliverablesAndLocation.location.country')
              : 'Remote'}
          </div>
        </div>

        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            {_get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERHOUR' ? (
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                width="7%"
              />
            ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERDAY' ? (
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Days.svg"
                width="7%"
              />
            ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERWEEK' ? (
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Weeks.svg"
                width="7%"
              />
            ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERMONTH' ? (
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Months.svg"
                width="7%"
              />
            ) : (
              <img
                src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                width="7%"
              />
            )}
            <div className="Skills-Required-title-Text">Duration</div>
          </div>
          <div className="Skills-Required-subText">
            {_get(job?.gig, 'scopeAndBudget.projectDuration', '-')}{' '}
            {getDurationLabel(_get(job?.gig, 'scopeAndBudget.durationUnit'))}
          </div>
        </div>

        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Budget.svg"
              width="8%"
            />
            <div className="Skills-Required-title-Text">Budget</div>
          </div>
          <div className="Skill-Required-Budget">
            {' '}
            {_get(job?.gig, 'scopeAndBudget.currency', '-')}{' '}
            {_get(job?.gig, 'scopeAndBudget.budget', '-')}
          </div>
        </div>

        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Availability+Required.svg"
              width="6%"
            />
            <div className="Skills-Required-title-Text">Availability Required</div>
          </div>

          <div className="Skills-Required-subText">
            {' '}
            {_get(job?.gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} Hours Per Week
          </div>
        </div>

        {/* it is required */}
        {/* <div className="Skills-Required">
          <div className="Skills-Required-Flex">
          <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Remote+Work.svg"
              width="7%"
            />
            <div className="Skills-Required-title-Text">
              {_get(job?.gig, 'deliverablesAndLocation.isRemote', '') === 'true'
                ? 'Remote Job'
                : 'Hybrid / Work From Office'}{' '}
            </div>
          </div>
        </div> */}

        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Start+Date.svg"
              width="6%"
            />
            <div className="Skills-Required-title-Text">Start Date</div>
          </div>
          <div className="Skills-Required-subText">
            {' '}
            {_get(job?.gig, 'scopeAndBudget.startDate', '')
              ? formatDate(_get(job?.gig, 'scopeAndBudget.startDate', ''))
              : '-'}{' '}
          </div>
        </div>

        {
          job?.gig?.scopeAndBudget?.hourlyRate === "" || job?.gig?.scopeAndBudget?.hourlyRate === 0 ?
            ""
            :
            <div className="Skills-Required">
              <div className="Skills-Required-Flex">
                <img
                  src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Hourly+Rate.svg"
                  width="6%"
                />
                <div className="Skills-Required-title-Text">Hourly Rate</div>
              </div>
              <div className="Skills-Required-subText">
                {_get(job?.gig, 'scopeAndBudget.hourlyRate', '0')
                  ? `${_get(job?.gig, 'scopeAndBudget.currency', 'R')} ${_get(
                    job?.gig,
                    'scopeAndBudget.hourlyRate',
                    '0'
                  )}/hr`
                  : '-'}
              </div>
            </div>
        }

        <div className="Skills-Required">
          <div className="Skills-Required-Flex">
            <img
              src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Area+Of+Expertise.svg"
              width="8%"
            />
            <div className="Skills-Required-title-Text">Area of Expertise</div>
          </div>
          <div className="Skills-Required-subText">
            {' '}
            {getExpertiseList(_get(job?.gig, 'fieldOfSpecialization', []))}
          </div>
        </div>
      </div>

      <div className="break-line-gig-details"></div>

      <div className="Skills-Required-main-Container">
        <div className="Title-Text">Skills Required</div>
        <div className="Skills-Required-Container">
          {_get(job?.gig, 'titleAndSkills.skillsRequired', []) ? (
            _get(job?.gig, 'titleAndSkills.skillsRequired', []).map((s) => {
              return (
                <div className="SubText-skills-gig-details">
                  <div> {s.engineerSkill}</div>
                </div>
              );
            })
          ) : (
            <p className="att-not-provided-msg">Not Provided</p>
          )}
        </div>
      </div>

      {!_isEmpty(_get(job?.gig, 'titleAndSkills.tools')) && (
        <div className="Skills-Required-main-Container">
          <div className="break-line-gig-details"></div>
          <div className="tools-tech-Text">Tools & Technologies</div>
          <div className="Tools-container">
            {_get(job?.gig, 'titleAndSkills.tools', []) ? (
              _get(job?.gig, 'titleAndSkills.tools', []).map((toolstechnologies, index) => {
                return (
                  <div className="SubText-skills-gig-details">
                    <div> {toolstechnologies}</div>
                  </div>
                );
              })
            ) : (
              <p className="att-not-provided-msg">Not Provided</p>
            )}
          </div>
        </div>
      )}

      {tokenDetails.userRole != undefined ? (
        <>
          <div className="Tand-Ccontainer">
            <div className="break-line-gig-details"></div>
            <div className="TandC-title-Text">Milestones</div>
            {
              job?.gig?.milestones?.map((milestone, index) => (
                <div className="TandC-Sub-Text">
                  <div className="TandC-Sub-Text-Title">
                    {
                      milestone?.title
                    }
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mt: 1,
                      "& span": {
                        fontSize: "14px",
                        mr: 1
                      }
                    }}>
                    <Box>
                      <Typography component={"span"}>
                        Milestone Amount:
                      </Typography>
                      <Typography component={"span"}>
                        {milestone?.amount}{job?.gig?.scopeAndBudget.currency || 'R'}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography component={"span"}>
                        Milestone End date:
                      </Typography>
                      <Typography component={"span"}>
                        {
                          formatDate(milestone.deadline)
                        }
                      </Typography>
                    </Box>
                  </Box>
                  <div className="TandC-Sub-Text-Title">
                    <ReadMore>
                      {
                        milestone?.description
                      }
                    </ReadMore>
                  </div>
                  {index !=
                    job?.gig?.milestones?.length - 1 && (
                      <Divider
                        sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                      />
                    )}
                  <div>
                    <div className="TandC-title-Text">Deliverables</div>

                    <div className="TandC-Sub-Text">
                      {milestone?.deliverables?.length > 0 ? (
                        <>
                          {milestone?.deliverables?.length > 0 &&
                            seeMore === false ? (
                            milestone?.deliverables?.map((e, index) => {
                              return index <= 1 ? (
                                <div>
                                  <ReadMore>{e?.deliverableTitle}</ReadMore>
                                  <div>
                                    <ReadMore>{e?.deliverableDesc}</ReadMore>
                                  </div>
                                  {index !=
                                    _get(job?.gig, 'deliverablesAndLocation.deliverables', []).length - 1 && (
                                      <Divider
                                        sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                      />
                                    )}
                                </div>
                              ) : (
                                ''
                              );
                            })
                          ) : (
                            <>
                              {milestone?.deliverables?.map((e, index) => {
                                return (
                                  <div>
                                    <ReadMore>{e?.deliverableTitle}</ReadMore>

                                    <div className="TandC-Sub-Text-Title">Deliverables Description</div>
                                    <div>
                                      <ReadMore>{e?.deliverableDesc}</ReadMore>
                                    </div>
                                    {index !=
                                      _get(job?.gig, 'deliverablesAndLocation.deliverables').length - 1 && (
                                        <Divider
                                          sx={{ borderColor: 'rgba(0, 0, 0, 0.2)', marginTop: '15px' }}
                                        />
                                      )}
                                  </div>
                                );
                              })}
                            </>
                          )}
                          {milestone?.deliverables?.length > 2 &&
                            seeMore === false ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMore(true)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See more(
                                {milestone?.deliverables?.length - 2})
                              </Button>
                            </Box>
                          ) : seeMore === true ? (
                            <Box>
                              <Button
                                onClick={() => setSeeMore(false)}
                                sx={{
                                  color: '#1976D2',
                                  textTransform: 'capitalize',
                                  fontSize: '15.5px',
                                  marginLeft: '-9px',
                                  marginTop: '20px'
                                }}
                              >
                                See less(
                                {milestone?.deliverables?.length - 2})
                              </Button>
                            </Box>
                          ) : (
                            ''
                          )}
                        </>
                      ) : (
                        <div className="TandC-Sub-Text">No deliverables found.</div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            }

          </div>
        </>
      ) : (
        <div></div>
      )}

      <div className="break-line-gig-details"></div>
      {/* it is required */}
      {/* {job?.gig?.deliverablesAndLocation?.specialrequirements?.file?.length > 0
        ?
        <div className="break-line-gig-details"></div>
        :
        ""
      } */}
      {!_isEmpty(_get(job?.gig, 'deliverablesAndLocation.specialrequirements.file')) &&
        (tokenDetails.userRole != undefined ? (
          <div>
            <div className="Attactment-Container-gig-detail">
              <div className="Attactment-title-Text-gig">
                Attachments (
                {_get(job?.gig, 'deliverablesAndLocation.specialrequirements.file', '') ? '1' : '0'}){' '}
              </div>
              {_get(job?.gig, 'deliverablesAndLocation.specialrequirements.file', '') ? (
                <div className="attachment-icon-gig-details">
                  <img src={Boundingbox} sx={{ width: '11px', height: '20px' }} />
                  <div
                    className="Attactment-Button-gig"
                    onClick={() =>
                      downloadAttachment(
                        _get(
                          job?.gig,
                          'deliverablesAndLocation.specialrequirements.file',
                          'Not Provided'
                        )
                      )
                    }
                  >
                    <div className="Att-Btn-Text">
                      {
                        _get(job?.gig, 'deliverablesAndLocation.specialrequirements.file', '').split(
                          '/'
                        )[6]
                      }
                    </div>
                  </div>
                </div>
              ) : (
                <p className="att-not-provided-msg">Not Provided</p>
              )}
            </div>
          </div>
        ) : (
          <div></div>
        ))}
      {!_isEmpty(_get(job?.gig, 'deliverablesAndLocation.specialrequirements.details')) &&
        (tokenDetails.userRole != undefined ? (
          <div>
            <div className="Tand-Ccontainer">
              <div className="TandC-title-Text">Special Terms & Conditions</div>
              {!!_get(job?.gig, 'deliverablesAndLocation.specialrequirements.details') && (
                <ReadMore>
                  {_get(
                    job?.gig,
                    'deliverablesAndLocation.specialrequirements.details',
                    'Not Provided'
                  )}
                </ReadMore>
              )}
            </div>
            <div className="break-line-gig-details"></div>
          </div>
        ) : (
          <div></div>
        ))}
      {coverLetterOpen &&
        userType === 'engineer' &&
        allgigAplicants?.engineerList?.[0]?.applicationDetails?.status !== 'APPLIED' &&
        _get(job?.gig, 'hireCount') === 0 ? (
        <div className="Cover-Letter-Container">
          <div className="Cover-Letter-title-Text">
            Add cover letter <div className="Cover-Letter-SubText">(100 - 1000 words)</div>
          </div>
          <RichTextEditor
            className="new-post-editor-gig-details"
            onChange={(value) => {
              setEditorValue(value);
              setCoverletterText(value.toString('html'));
              sethasChanged(true);
            }}
            value={editorValue}
            toolbarConfig={toolBarConfig}
            placeholder="Type here"
          />

          {!!editorValue &&
            !editorValue.getEditorState().getCurrentContent().hasText() &&
            hasChanged && <p className="error-msg-cover-letter">Cover letter is required!</p>}

          {imgUploadedtext ? (
            <IconButton
              sx={{
                marginLeft: '-20px'
              }}
              variant="contained"
              onClick={() => fileInputRef.current.click()}
            >
              <div className="Add-Attachment-Text">
                <input type="file" hidden ref={fileInputRef} />
                <div className="drag-drop-gig-details">
                  <DragDropIcon
                    onFileChange={(e) => {
                      handleFileUploaded(e);
                    }}
                    icons={<img className='file-upload-icon-detail' src={CoverIcon} alt="CoverIcon" />}
                    text="Drag your attachment files here"
                  ></DragDropIcon>
                </div>
              </div>
            </IconButton>
          ) : (
            <div className="Add-Attachment-TextAfter-Upload">File has been uploaded</div>
          )}
        </div>
      ) : (
        <div></div>
      )}

      {
        checkIfVerified(referralsList).length > 0 && (
          <div className='reviews-wrapper'>
            <div className='reviews-heading'>{'Business Owner’s recent history'}</div>
            {
              !!referralsList
              &&
              referralsList.length > 0
              &&
              checkIfVerified(referralsList).length > 0
              &&
              checkIfVerified(referralsList).map((item, index) => (
                <div>
                  <div className='spacer'></div>
                  <div className='review-gig-name'>{item.gigId.titleAndSkills.projectTitle}</div>
                  <div className='stars-row'>
                    <ReactStars classNames={'stars'} count={5} activeColor="#ffd700" size={25} edit={false} value={!!item.publicFeedback.feedbackStarData && (item.publicFeedback.feedbackStarData.reduce((sum, feedback) => sum + (feedback.feedbackRating > 0 ? parseInt(feedback.feedbackRating) : 0), 0) / Math.max(item.publicFeedback.feedbackStarData.filter(feedback => feedback.feedbackRating > 0).length, 1)).toFixed(2)} />
                    <div className='stars-count-txt'>{!!item.publicFeedback.feedbackStarData && (item.publicFeedback.feedbackStarData.reduce((sum, feedback) => sum + (feedback.feedbackRating > 0 ? parseInt(feedback.feedbackRating) : 0), 0) / Math.max(item.publicFeedback.feedbackStarData.filter(feedback => feedback.feedbackRating > 0).length, 1)).toFixed(2)}</div>
                  </div>
                  <div className='feedback-txt'>{`“${item.publicFeedback.feedbackTextData}”`}</div>
                </div>
              ))
            }
          </div>
        )
      }

      {userType === 'engineer' &&
        allgigAplicants?.engineerList?.[0]?.applicationDetails?.status !== 'APPLIED' &&
        _get(job?.gig, 'hireCount') === 0 ? (
        <div className="Back-Btn-Main-Container">
          <div className="Back-Apply-Checkbox-Flex">
            <div className="Checkbox-Text-flex">
              <input
                type="checkbox"
                id="myCheck"
                onChange={() => setCoverLetterOpen(!coverLetterOpen)}
              />
              <div className="CL-text">Cover Letter</div>
            </div>

            <BackBtn
              onClick={() => {
                applygig(id, _get(job?.gig, 'titleAndSkills.projectTitle', ''));
                if (applyStatus) {
                  window.location.reload(true);
                }
              }}
              variant="contained"
            >
              {loader ? <CircularProgress /> : 'Apply'}
            </BackBtn>

            <BackBtn
              variant="contained"
              onClick={() => {
                history.goBack(-1);
              }}
            >
              BACK
            </BackBtn>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFeedback: (details) => dispatch(GigAllActions.getAllFeedback(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GigDetails_Attributes);
