import axios from 'axios';
import _toString from 'lodash/toString';
import config from '../config';
import { tokenHeaders } from '../utils/user';
import showVaildationErrors from '../utils/errorEvaluation';
require('dotenv').config();

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000
});

const axiosInstanceforCountryAPI = axios.create({
  baseURL: 'https://countriesnow.space/api/v0.1/countries',
  timeout: 6000
});

const onSuccess = (response) => {
  return response.data || {};
};

const onError = (error, config) => {
  return redirectPrivilege(error.data ? error.data : error);
};

const redirectPrivilege = (errorObj) => {
  // redirections for 401 error
  const { status } = errorObj;
  const referer = window.location.href;
  switch (_toString(status)) {
    case '401':
      window.location.href = `/signin?redirect=${referer}`;
    case '400': {
      showVaildationErrors(errorObj);
      return errorObj;
    }
    case '500': {
      showVaildationErrors(errorObj);
      return errorObj;
    }
    default:
      showVaildationErrors(errorObj || {});
      return errorObj;
  }
};

const apiService = async (config) => {
  const { method = 'get', url, headers, data } = config;

  const options = {
    method,
    url,
    headers: {
      ...tokenHeaders(),
      ...headers
    },
    data
  };

  try {
    const apiResponse = await axiosInstance.request(options);
    return onSuccess(apiResponse);
  } catch (ex) {
    const response = ex.response || {};
    return onError(
      {
        status: response.statuscode || response.status || 500,
        ...response
      },
      config
    );
  }
};

const apiHandler = (config) => {
  const { method = 'get', url, headers, data } = config;

  const options = {
    method,
    url,
    headers: {
      ...tokenHeaders(),
      ...headers
    },
    data
  };


  return axiosInstance(options);

};

export const signUpApiService = async (config) => {
  const { method = 'get', url, headers, data } = config;

  const options = {
    method,
    url,
    headers: {
      ...tokenHeaders(),
      ...headers
    },
    data
  };

  try {
    const apiResponse = await axiosInstance.request(options);
    return onSuccess(apiResponse);
  } catch (ex) {
    const response = ex.response || {};
    return response.data ? response.data : response;
  }
};

export const apiServiceForCountryAPI = async (config) => {
  const { method, url, headers, data } = config;

  const options = {
    method,
    url,
    headers: {
      ...tokenHeaders(),
      ...headers
    },
    data
  };
  try {
    const apiResponse = await axiosInstanceforCountryAPI.request(options);
    return onSuccess(apiResponse);
  } catch (ex) {
    const response = ex.response || {};
    return onError(
      {
        status: response.statuscode || response.status || 500,
        ...response
      },
      config
    );
  }
};

export default apiService;

export {
  apiHandler
}
