import React, { useEffect, useState, useRef } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Button,
  Divider,
  Typography,
  IconButton,
  CircularProgress,
  Box,
  Chip,
  Avatar,
  Grid
} from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import Reportlogo from '../../../assests/report.png';
import SandClock from '../../../assests/Timer.png';
import { TypographyBold, PaddedBox, CustomRow, EditedCard, TimerImg } from '../../app.styles';
import { useLocation } from 'react-router-dom';
import {
  getGigLocation,
  formatDate,
  getDurationLabel,
  getJobType,
  checkIfVerified
} from '../../../utils/helpers';
import { getGigAllApplicants, getGigsById, getAllFeedbackApi } from '../../../services/gig';
import * as GigAllActions from '../../../store/actions/gig';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Applied from '../../../assests/check_circle.png';
import { styled } from '@mui/material/styles';
import './gigDetail.css';
import { getUserType, getTokenDetails } from '../../../utils/user';
import Header from '../../../components/HomePage/Header';
import Footer1 from '../../../components/footer1/footer';
import SIgnIN_UP from './SIgnIN_UP';
import { applyToGigApi } from '../../../services/engineerGig';
import { getProfileDetApi } from '../../../services/profile';
import Dummypic from '../../../assests/2.jpg';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import InvoiceModal from '../../../containers/invoices/common/InvoiceModal/index';
import { getUploadApi } from '../../../services/upload';
import days from "../../../assests/days.jpg"
import face from "../../../assests/face.png"
import HourlyRate from "../../../assests/HourlyRate.png"
import Boundingbox from "../../../assests/attach_file.png"
import Areaofexpertise from "../../../assests/Areaofexpertise.png"
import Duration from "../../../assests/Duration.png"
import Budget from "../../../assests/Budget.png"
import RemoteJob from "../../../assests/RemoteJob.png"
import StartDate from "../../../assests/StartDate.png"
import Hours from "../../../assests/Hours.svg";
import Days from "../../../assests/Days.svg";
import Weeks from "../../../assests/Weeks.svg";
import Months from "../../../assests/Months.svg";
import { BackBtn, NoLongerApctImg } from '../../app.styles';
import './gigDetailsAttributes.scss';
import ReactStars from "react-rating-stars-component";
import Pagination from '@mui/material/Pagination';
import { getDurationUnit } from '../../../utils/helpers'
import PageLoader from '../../../components/Loaders/PageLoader';
const ReadMore = ({ children }) => {
  let text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p style={{ width: '100%', display: 'block', wordBreak: 'break-all' }}>
      {isReadMore ? (text.length > 380 ? text.slice(0, 380) + '.....' : text) : text}
      <br></br>
      {text.length >= 380 ? (
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? 'See more' : 'See less'}
        </span>
      ) : (
        ''
      )}
    </p>
  );
};

const GigDetailpublicView = (props) => {
  const [job, setList] = useState(undefined);
  const [userType, setUserType] = React.useState(getUserType());
  const [allgigAplicants, setAllgigAplicants] = useState();
  const [applyStatus, setApplyStatus] = useState();
  const [profileInfo, setProfileInfo] = useState();
  const [addInvoiceModalOpen, setaddInvoiceModalOpen] = useState(false);
  const [coverLetterOpen, setCoverLetterOpen] = useState(false);
  const [coverletterText, setCoverletterText] = useState();
  const [attachmentFile, setAttachmentFile] = useState();
  const [attachmentFileName, setAttachmentFileName] = useState('');
  const [loader, setLoader] = useState(false);
  const [imgUploadedtext, setImgUploadedtext] = useState(true);
  const [seeMore, setSeeMore] = useState(false);
  const [feedbacks, setfeedbacks] = useState([]);
  const [readMoreDetails, setreadMoreDetails] = useState(false);
  const [feedbackPaginationData, setfeedbackPaginationData] = useState({
    currentPage: 0,
    pageSize: 5,
    totalPages: null,
    totalRecords: null
  });

  const [referralsList, setreferralsList] = useState([]);

  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const tokenDetails = getTokenDetails();

  const getAllFeedbackParams = (gigd) => {
    return {
      // paginationInput: {
      //   pageNumber: feedbackPaginationData.currentPage + 1,
      //   pageSize: feedbackPaginationData.pageSize
      // },
      recieverId: gigd.gig.userId
    };
  };

  const getAllFeedbackParamsPagination = (gigd, val) => {
    return {
      // paginationInput: {
      //   pageNumber: val,
      //   pageSize: feedbackPaginationData.pageSize
      // },
      recieverId: gigd.gig.userId
    };
  };

  const handleChange = async (event, value) => {
    const gigd = await getGigsById(id);
    setList(gigd);
    await getAllFeedbackApi(getAllFeedbackParamsPagination(gigd, value))
      .then((e) => {
        // setfeedbackPaginationData(e.data.paginationResult)
        setreferralsList(e.data.referralsList);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const setDataAllFeedback = (data) => {
    setfeedbacks(data.referralsList);
    setfeedbackPaginationData({
      currentPage: data.paginationResult.currentPage,
      pageSize: data.paginationResult.pageSize,
      totalPages: data.paginationResult.totalPages,
      totalRecords: data.paginationResult.totalRecords
    });
    // console.log(data);
  };

  const fetchData = async () => {
    const gigd = await getGigsById(id);
    setList(gigd);
    await getAllFeedbackApi(
      getAllFeedbackParams(gigd)
      // setDataAllFeedback,
      // props.getAllFeedback,
    )
      .then((e) => {
        // console.log("HERE WE ARE!!!!",e)
        setreferralsList(e.data.referralsList);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (userType === 'engineer') {
      getProfileDetApi(setProfileInfo);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (userType === 'engineer') {
      const obj = {
        gigId: id,
        userId: profileInfo?.basicInfo?.userId
      };
      if (profileInfo?.basicInfo?.userId) {
        getGigAllApplicants(obj, setAllgigAplicants);
      }
    }
  }, [profileInfo]);

  useEffect(() => {
    if (applyStatus) {
      window.location.reload();
    }
  }, [applyStatus]);

  const handleFileUploaded = async (event) => {
    setLoader(true);
    setImgUploadedtext(false);
    const file = event.target.files[0];
    try {
      getUploadApi(file)
        .then((e) => {
          setAttachmentFile(e.data.fileUrl);
          setAttachmentFileName(file.name);
          setLoader(false);
        })
        .catch((err) => {
          console.error(err);
          toast.error('Error uploading file. Please try again!');
        });
    } catch (err) {
      console.error(err);
      toast.error('Error uploading file. Please try again!');
    }
  };

  const applygig = async (id) => {
    const objj = {
      gigId: id,
      coverLetter: coverletterText,
      attachment: attachmentFile
    };
    const gigApply = await applyToGigApi(objj, setApplyStatus);
  };

  const getExpertiseList = (expertise) => {
    return Array.prototype.map.call(expertise, (s) => ` ${s}`).toString();
  };

  const downloadAttachment = (file) => {
    window.open(file);
  };

  const profilePic = job?.companyDetails?.businessProfileData?.companyDetails?.profilePic
    ? job?.companyDetails?.businessProfileData?.companyDetails?.profilePic
    : Dummypic;

  const fileInputRef = useRef(null);

  return (
    <>
      {_isEmpty(job) && (
        <Grid
          container
          item
          justifyContent="center"
          xs={12}
          className="h-margin-top-10 h-padding-top-16"
        >
          <PageLoader />
        </Grid>
      )}
      <PaddedBox
        style={{
          paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true
            ? '240px'
            : '0px'
            }`
        }}
      >
        <Header />
        {addInvoiceModalOpen && (
          <InvoiceModal
            job={job?.gig}
            addInvoiceModalOpen={addInvoiceModalOpen}
            setaddInvoiceModalOpen={setaddInvoiceModalOpen}
          />
        )}

        {_get(job?.gig, 'status') === 'ACTIVE' ? (
          <div className="gig-details-main-container-1">
            <div className="public-view-details-1">
              <div className="Edge-pub-pagging">
                <div className="Profile-Container-gig-detail">
                  <Avatar
                    src={_get(
                      job?.companyDetails,
                      'businessProfileData.companyDetails.profilePic',
                      ''
                    )}
                    sx={{
                      width: '96px',
                      height: '96px',
                      borderRadius: '100%'
                    }}
                  ></Avatar>

                  <div className="Profile-Name-gig-detail">
                    {' '}
                    {_get(
                      job?.companyDetails,
                      'businessProfileData.companyDetails.companyName',
                      ''
                    )}{' '}
                  </div>
                </div>
                <div className="Field-Of-Specialization-Text">
                  <ReadMore>{_get(job?.gig, 'titleAndSkills.projectTitle', '')}</ReadMore>
                </div>
                <div className="GiglinkandField-Container">
                  <div className="Field-Container-Public">
                    <div className="FieldOf-JobType">
                      Job Type : {getJobType(job?.gig, 'titleAndSkills.jobType', 'Not Disclosed')}
                    </div>
                    <div className="Created-AtText">
                      Posted on :{' '}
                      {_get(job?.gig, 'updatedAt')
                        ? formatDate(_get(job?.gig, 'updatedAt', ''))
                        : '-'}
                    </div>
                  </div>

                  {userType === 'engineer' &&
                    allgigAplicants?.engineerList?.[0]?.applicationDetails?.status === 'APPLIED' ? (
                    <div className="Applied-Box">
                      <div className="Applied-Text">Applied</div>
                      <img src={Applied} sx={{ width: '25px', height: '25px' }} />
                    </div>
                  ) : userType === 'engineer' && _get(job?.gig, 'hireCount') >= 1 ? (
                    <div className="No-Longer-Apct">
                      <NoLongerApctImg>
                        {' '}
                        <img src={Reportlogo} />{' '}
                      </NoLongerApctImg>
                      <div className="No-Longer-Apct-Text">No longer considering applications</div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>

                <div className="break-line-gig-details"></div>

                <div className="Project-Detail-Container">
                  <div className="PD-title-Text">Project Details</div>
                  <Box sx={{ fontSize: '15.5px', color: 'black' }}>
                    {!!_get(job?.gig, 'titleAndSkills.projectDetails', '') && (
                      <div
                        style={{
                          height: `${readMoreDetails ? 'fit-content' : '200px'}`,
                          overflow: 'hidden',
                        }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: _get(job?.gig, 'titleAndSkills.projectDetails', '')
                          }}
                        ></div>
                      </div>
                    )}

                    {!!_get(job?.gig, 'titleAndSkills.projectDetails', '') &&
                      _get(job?.gig, 'titleAndSkills.projectDetails', '').length > 353 && (
                        <button
                          className="project-title-see-more-btn"
                          onClick={() => {
                            setreadMoreDetails(!readMoreDetails);
                          }}
                        >{`${readMoreDetails ? 'See Less' : 'See More'}`}</button>
                      )}
                  </Box>
                </div>

                <div className="break-line-gig-details"></div>

                <div className="Skills-Container-gig-details">
                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Location.svg"
                        width="8.2%"
                      />
                      <div className="Skills-Required-title-Text">Location</div>
                    </div>
                    <div className="Skills-Required-Location">
                      {_get(job?.gig, 'deliverablesAndLocation.location.country')
                        ? _get(job?.gig, 'deliverablesAndLocation.location.country')
                        : 'Remote'}
                    </div>
                  </div>

                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      {_get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERHOUR' ? (
                        <img
                          src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                          width="7%"
                        />
                      ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERDAY' ? (
                        <img
                          src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Days.svg"
                          width="7%"
                        />
                      ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERWEEK' ? (
                        <img
                          src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Weeks.svg"
                          width="7%"
                        />
                      ) : _get(job?.gig, 'scopeAndBudget.durationUnit') === 'PERMONTH' ? (
                        <img
                          src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Months.svg"
                          width="7%"
                        />
                      ) : (
                        <img
                          src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Project+Duration+Hours.svg"
                          width="7%"
                        />
                      )}
                      <div className="Skills-Required-title-Text">Duration</div>
                    </div>
                    <div className="Skills-Required-subText">
                      {_get(job?.gig, 'scopeAndBudget.projectDuration', '-')}{' '}
                      {getDurationLabel(_get(job?.gig, 'scopeAndBudget.durationUnit'))}
                    </div>
                  </div>

                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Budget.svg"
                        width="8%"
                      />
                      <div className="Skills-Required-title-Text">Budget</div>
                    </div>
                    <div className="Skill-Required-Budget">
                      {' '}
                      {_get(job?.gig, 'scopeAndBudget.currency', '-')}{' '}
                      {_get(job?.gig, 'scopeAndBudget.budget', '-')}
                    </div>
                  </div>

                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Availability+Required.svg"
                        width="6%"
                      />
                      <div className="Skills-Required-title-Text">Availability Required</div>
                    </div>

                    <div className="Skills-Required-subText">
                      {' '}
                      {_get(job?.gig, 'scopeAndBudget.availabilityHoursPerWeek', '')} Hours Per Week
                    </div>
                  </div>

                 {/* It is required */}
                  {/* <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Remote+Work.svg"
                        width="7%"
                      />
                      <div className="Skills-Required-title-Text">
                        {_get(job?.gig, 'deliverablesAndLocation.isRemote', '') === 'true'
                          ? 'Remote Job'
                          : 'Hybrid / Work From Office'}{' '}
                      </div>
                    </div>
                  </div> */}

                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Start+Date.svg"
                        width="6%"
                      />
                      <div className="Skills-Required-title-Text">Start Date</div>
                    </div>
                    <div className="Skills-Required-subText">
                      {' '}
                      {_get(job?.gig, 'scopeAndBudget.startDate', '')
                        ? formatDate(_get(job?.gig, 'scopeAndBudget.startDate', ''))
                        : '-'}{' '}
                    </div>
                  </div>

                  {
                  job?.gig?.scopeAndBudget?.hourlyRate === "" || job?.gig?.scopeAndBudget?.hourlyRate === 0 ?
                    ""
                    :
                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Hourly+Rate.svg"
                        width="6%"
                      />
                      <div className="Skills-Required-title-Text">Hourly Rate</div>
                    </div>
                    <div className="Skills-Required-subText">
                      {_get(job?.gig, 'scopeAndBudget.hourlyRate', '0')
                        ? `${_get(job?.gig, 'scopeAndBudget.currency', 'R')} ${_get(
                          job?.gig,
                          'scopeAndBudget.hourlyRate',
                          '0'
                        )}/hr`
                        : '-'}
                    </div>
                  </div>
                  }

                  <div className="Skills-Required">
                    <div className="Skills-Required-Flex">
                      <img
                        src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Gig+Details+Area+Of+Expertise.svg"
                        width="8%"
                      />
                      <div className="Skills-Required-title-Text">Area of Expertise</div>
                    </div>
                    <div className="Skills-Required-subText">
                      {' '}
                      {getExpertiseList(_get(job?.gig, 'fieldOfSpecialization', []))}
                    </div>
                  </div>
                </div>
                <div className="break-line-gig-details"></div>

                  <div className="Skills-Required-main-Container">
                    <div className="Title-Text">Skills Required</div>
                    <div className="Skills-Required-Container">
                      {_get(job?.gig, 'titleAndSkills.skillsRequired', []) ? (
                        _get(job?.gig, 'titleAndSkills.skillsRequired', []).map((s) => {
                          return (
                            <div className="SubText-skills-gig-details">
                              <div> {s.engineerSkill}</div>
                            </div>
                          );
                        })
                      ) : (
                        <p className="att-not-provided-msg">Not Provided</p>
                      )}
                    </div>
                  </div>

                    <div className="Skills-Required-main-Container">
                      <div className="break-line-gig-details"></div>
                      <div className="tools-tech-Text">Tools & Technologies</div>
                      <div className="Tools-container">
                        {_get(job?.gig, 'titleAndSkills.tools', []) ? (
                          _get(job?.gig, 'titleAndSkills.tools', []).map(
                            (toolstechnologies, index) => {
                              return (
                                <div className="SubText-skills-gig-details">
                                  <div> {toolstechnologies}</div>
                                </div>
                              );
                            })
                        ) : (
                          <p className="att-not-provided-msg">Not Provided</p>
                        )}
                      </div>
                    </div>

                {tokenDetails.userRole != undefined ? (
                  <>
                    <div className="Tand-Ccontainer">
                      <div className="break-line-gig-details"></div>
                      <div className="TandC-title-Text">Deliverables</div>

                      <div className="TandC-Sub-Text">
                        {job?.gig && job.gig.deliverablesAndLocation.deliverables.length > 0 ? (
                          <>
                            {job?.gig &&
                              job.gig.deliverablesAndLocation.deliverables.length > 0 &&
                              seeMore === false ? (
                              job.gig.deliverablesAndLocation.deliverables.map((e, index) => {
                                return index <= 1 ? (
                                  <div>
                                    <div className="TandC-Sub-Text-Title">Deliverables Title</div>
                                    <ReadMore>{e?.deliverableTitle}</ReadMore>

                                    <div className="TandC-Sub-Text-Title">
                                      Deliverables Description
                                    </div>
                                    <div>
                                      <ReadMore>{e?.deliverableDesc}</ReadMore>
                                    </div>
                                    {index !=
                                      _get(job?.gig, 'deliverablesAndLocation.deliverables')
                                        .length -
                                      1 && (
                                        <Divider
                                          sx={{
                                            borderColor: 'rgba(0, 0, 0, 0.2)',
                                            marginTop: '15px'
                                          }}
                                        />
                                      )}
                                  </div>
                                ) : (
                                  ''
                                );
                              })
                            ) : (
                              <>
                                {job.gig.deliverablesAndLocation.deliverables.map((e, index) => {
                                  return (
                                    <div>
                                      <div className="TandC-Sub-Text-Title">Deliverables Title</div>
                                      <ReadMore>{e?.deliverableTitle}</ReadMore>

                                      <div className="TandC-Sub-Text-Title">
                                        Deliverables Description
                                      </div>
                                      <div>
                                        <ReadMore>{e?.deliverableDesc}</ReadMore>
                                      </div>
                                      {index !=
                                        _get(job?.gig, 'deliverablesAndLocation.deliverables')
                                          .length -
                                        1 && (
                                          <Divider
                                            sx={{
                                              borderColor: 'rgba(0, 0, 0, 0.2)',
                                              marginTop: '15px'
                                            }}
                                          />
                                        )}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                            {job?.gig &&
                              job.gig.deliverablesAndLocation.deliverables.length > 2 &&
                              seeMore === false ? (
                              <Box>
                                <Button
                                  onClick={() => setSeeMore(true)}
                                  sx={{
                                    color: '#1976D2',
                                    textTransform: 'capitalize',
                                    fontSize: '15.5px',
                                    marginLeft: '-9px',
                                    marginTop: '20px'
                                  }}
                                >
                                  See more(
                                  {job?.gig &&
                                    job.gig.deliverablesAndLocation.deliverables.length - 2}
                                  )
                                </Button>
                              </Box>
                            ) : seeMore === true ? (
                              <Box>
                                <Button
                                  onClick={() => setSeeMore(false)}
                                  sx={{
                                    color: '#1976D2',
                                    textTransform: 'capitalize',
                                    fontSize: '15.5px',
                                    marginLeft: '-9px',
                                    marginTop: '20px'
                                  }}
                                >
                                  See less(
                                  {job?.gig &&
                                    job.gig.deliverablesAndLocation.deliverables.length - 2}
                                  )
                                </Button>
                              </Box>
                            ) : (
                              ''
                            )}
                          </>
                        ) : (
                          <div className="TandC-Sub-Text">No deliverables found.</div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div></div>
                )}

                <div className="break-line-gig-details"></div>

                {tokenDetails.userRole != undefined ? (
                  <div>
                    <div className="Attactment-Container-gig-detail">
                      <div className="Attactment-title-Text-gig">
                        Attachments (
                        {_get(job?.gig, 'deliverablesAndLocation.specialrequirements.file', '')
                          ? '1'
                          : '0'}
                        ){' '}
                      </div>
                      {_get(job?.gig, 'deliverablesAndLocation.specialrequirements.file', '') ? (
                        <div className="attachment-icon-gig-details">
                          <img src={Boundingbox} sx={{ width: '11px', height: '20px' }} />
                          <div
                            className="Attactment-Button-gig"
                            onClick={() =>
                              downloadAttachment(
                                _get(
                                  job?.gig,
                                  'deliverablesAndLocation.specialrequirements.file',
                                  'Not Provided'
                                )
                              )
                            }
                          >
                            <div className="Att-Btn-Text">
                              {
                                _get(
                                  job?.gig,
                                  'deliverablesAndLocation.specialrequirements.file',
                                  ''
                                ).split('/')[6]
                              }
                            </div>
                          </div>
                        </div>
                      ) : (
                        <p className="att-not-provided-msg">Not Provided</p>
                      )}
                    </div>
                    <div className="break-line-gig-details"></div>
                  </div>
                ) : (
                  <div></div>
                )}
                {tokenDetails.userRole != undefined ? (
                  <div>
                    <div className="Tand-Ccontainer">
                      <div className="TandC-title-Text">Special Terms & Conditions</div>
                      {!!_get(job?.gig, 'deliverablesAndLocation.specialrequirements.details') && (
                        <ReadMore>
                          {_get(
                            job?.gig,
                            'deliverablesAndLocation.specialrequirements.details',
                            'Not Provided'
                          )}
                        </ReadMore>
                      )}
                    </div>

                    <div className="break-line-gig-details"></div>
                  </div>
                ) : (
                  <div></div>
                )}

                {coverLetterOpen &&
                  userType === 'engineer' &&
                  allgigAplicants?.engineerList?.[0]?.applicationDetails?.status !== 'APPLIED' &&
                  _get(job?.gig, 'hireCount') === 0 ? (
                  <div className="Cover-Letter-Container">
                    <div className="Cover-Letter-title-Text">
                      Add cover letter{' '}
                      <div className="Cover-Letter-SubText">(100 - 1000 words)</div>
                    </div>
                    <RichTextEditor
                      className="new-post-editor-gig-details"
                      onChange={(value) => {
                        setEditorValue(value);
                        setCoverletterText(value.toString('html'));
                        sethasChanged(true);
                      }}
                      value={editorValue}
                      toolbarConfig={toolbarConfig}
                      placeholder="Type here"
                    />

                    {!!editorValue &&
                      !editorValue.getEditorState().getCurrentContent().hasText() &&
                      hasChanged && (
                        <p className="error-msg-cover-letter">Cover letter is required!</p>
                      )}

                    {imgUploadedtext ? (
                      <IconButton
                        sx={{
                          marginLeft: '-20px'
                        }}
                        variant="contained"
                        onClick={() => fileInputRef.current.click()}
                      >
                        <div className="Add-Attachment-Text">
                          <input type="file" hidden ref={fileInputRef} />
                          <div className="drag-drop-gig-details">
                            <DragDropIcon
                              onFileChange={(e) => {
                                handleFileUploaded(e);
                              }}
                              icons={<img src={CoverIcon} alt="CoverIcon" />}
                              text="Drag your attachment files here"
                            ></DragDropIcon>
                          </div>
                        </div>
                      </IconButton>
                    ) : (
                      <div className="Add-Attachment-TextAfter-Upload">File has been uploaded</div>
                    )}
                  </div>
                ) : (
                  <div></div>
                )}

                {userType === 'engineer' &&
                  allgigAplicants?.engineerList?.[0]?.applicationDetails?.status !== 'APPLIED' &&
                  _get(job?.gig, 'hireCount') === 0 ? (
                  <div className="Back-Btn-Main-Container">
                    <div className="BackApply-Checkbox-Flex">
                      <div className="Checkbox-Text-flex">
                        <input
                          type="checkbox"
                          id="myCheck"
                          onChange={() => setCoverLetterOpen(!coverLetterOpen)}
                        />
                        <div className="CL-text">Cover Letter</div>
                      </div>

                      <BackBtn
                        onClick={() => {
                          applygig(id);
                          if (applyStatus) {
                            window.location.reload(true);
                          }
                        }}
                        variant="contained"
                      >
                        {loader ? <CircularProgress /> : 'Apply'}
                      </BackBtn>
                      <div>
                        <BackBtn
                          variant="contained"
                          onClick={() => {
                            history.goBack(-1);
                          }}
                        >
                          BACK
                        </BackBtn>

                        {userType === 'engineer' ? (
                          <div>
                            {location.pathname.includes('active') && (
                              <Button
                                variant="contained"
                                className="primaryMainBtn"
                                sx={{ width: '140px' }}
                                onClick={() => {
                                  setaddInvoiceModalOpen(true);
                                }}
                              >
                                New Invoice
                              </Button>
                            )}
                          </div>
                        ) : (
                          <div></div>
                        )}
                        {location.pathname.includes('hires') && (
                          <BackBtn
                            variant="contained"
                            onClick={() => {
                              history.push(`/hires/currenthires`);
                            }}
                          >
                            Unhire
                          </BackBtn>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            {tokenDetails.userRole != undefined ? (
              <div></div>
            ) : (
              <div className="login-pop-up">
                <SIgnIN_UP />
              </div>
            )}
          </div>
        ) : (
          <div className="Inactive-Closedgig">
            <TimerImg src={SandClock} />
            <div className="Link-Text-public-view">This Link does not exist</div>
            <div className="Inactive-Closedgig-Text">
              The gig you are looking for is inactive or has been closed by the Business Owner
            </div>
          </div>
        )}
      </PaddedBox>
      <div>
        <Footer1 />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    profile: state.profile
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllFeedback: (details) => dispatch(GigAllActions.getAllFeedback(details)),
    resetProfileSuccess: (details) => dispatch(resetProfileSuccess(details)),
    setProfileDetails: (details) => dispatch(setProfileDetails(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GigDetailpublicView);
