import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Avatar, Box, Button, Grid, ListItem, Paper, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _indexOf from 'lodash/indexOf';
import { TextInput } from '../../components/Messenger/TextInput';
import { MessageLeft, MessageRight } from '../../components/Messenger/Message';
import { setChatMessage } from '../../store/actions/chat';
import _map from 'lodash/map';
import { getTokenDetails, getUserType } from '../../utils/user';
import { format } from 'date-fns';
import './chat.css';

const ChatMessages = ({ gigData, chatData, sendMsg, messages }) => {
  const tokenDetails = getTokenDetails();
  const token = tokenDetails.userId;
  const history = useHistory();

  useEffect(() => {
    socket.emit('get-user-chat', chatData.cId);
    socket.emit('get-all-users', token);
  }, [chatData.cId]);

  useEffect(() => {
    if (messages.length) {
      let lastMsg = messages[messages.length - 1];
      socket.emit('checkPointUpdate', { chatid: lastMsg.cId, messageId: lastMsg._id });
      socket.emit('get-all-users', token);
    }
  }, [messages]);

  const sendMessage = (msg, type) => {
    const msgObj = {
      data: msg,
      messageType: type
    };
    sendMsg && sendMsg(msgObj);
  };

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const extractDate = (msg) => {
    return format(msg.createdAt ? new Date(msg.createdAt) : new Date(), 'dd/MM/yy HH:mm');
  };

  const onBusinessDetailClick = (_payload) => {
    if (getUserType() === "business") {
      history.push(`/gig/active/${_payload.gigId}`)
    } else {
      history.push(`/discover/${_payload.gigId}`)
    }
  }
  const ChatDescriptionEngineer = () => {
    return (
      <Grid
        container
        direction={'row'}
        alignItems={'center'}
        className="chat-main-boyd-info-container"
      >
        <Grid direction={'row'} container xs={10} alignItems={'center'}>
          <ListItem className="chat-main-body-profile">
            <Avatar
              className="chat-main-body-profile-pic"
              src={
                chatData.receiverId === token
                  ? chatData.senderProfilePic
                  : chatData.receiverProfilePic
              }
            />
            <div className='chat-main-body-profile-info'>
              <Typography className="chat-main-body-profile-name">{chatData.name}</Typography>
              <Typography className="chat-main-body-profile-gigname" onClick={() => onBusinessDetailClick(chatData)}>
                {gigData?.titleAndSkills?.projectTitle}
              </Typography>
            </div>
          </ListItem>
        </Grid>
        <Grid item xs={2}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className='chat-main-body-profile-view-button'
            onClick={() => {
              if (getUserType() === "business") {
                history.push(`/engineer/details/${chatData.receiverId === token ? chatData.senderId : chatData.receiverId}`)
              } else {
                history.push(`/discover/${chatData.gigId}`)
              }
            }}>
            View Profile
          </Button>
        </Grid>
      </Grid>
    );
  };
  return (
    <Box className="chat-Main-Body">
      <ChatDescriptionEngineer />
      <Paper id="style-1" className="chat-Body">
        {messages.map((msg, index) => {
          {
            return token === msg.senderId ? (
              <div className="chat-body-right">
                <MessageRight
                  message={msg}
                  timestamp={extractDate(msg)}
                  key={`${extractDate(msg)}-${index}`}
                  messageType={msg.messageType}
                />
              </div>
            ) : (
              <MessageLeft
                message={msg}
                timestamp={extractDate(msg)}
                key={`${extractDate(msg)}-${index}`}
                messageType={msg.messageType}
              />
            );
          }
        })}
        <AlwaysScrollToBottom />
      </Paper>
      <TextInput sendMessage={sendMessage} />
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    messages: state.chat.messages || []
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChatMessage: (details) => dispatch(setChatMessage(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatMessages);
