import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button, FormControl, InputLabel, Select, MenuItem, Box, TextField, CircularProgress, Dialog, DialogContent, DialogTitle } from '@mui/material';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import { Formik, Form, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { newGigProjectDeliverablesSchema } from '../../../../utils/formikValidations';
import FormTextField from '../../../../components/util/FormTextField';
import { setNewGigSuccess, setNewGigError } from '../../../../store/actions/newGig';
import { saveNewGigApi, saveNewDraftGigApi, getGigs } from '../../../../services/gig';
import { getCountryStateCity } from '../../../../services/expertisewithskills';
import HelpIcon from '@mui/icons-material/Help';
import DatePicker from '@mui/lab/DatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { blue } from '@mui/material/colors';
import { useHistory } from 'react-router-dom';
import { getUploadApi } from '../../../../services/upload'
import { styled } from '@mui/material/styles';
import RaiseQueryIcon from '../../../../assests/RaiseQueryIcon.svg';
import { getUniversityApi } from '../../../../services/expertisewithskills';
import "./Step3.scss";
import Step3 from '../../../../assests/step3.png';
import DeleteImg from '../../../../assests/Delete_Icon.png';
import CheckIcon from '../../../../assests/check_circle_blue.png';
import { ButtonContainerStep2, ButtonContainerStep3, DraftBtnStep3, GigBtnStep3, PaddedBox } from '../../../app.styles';
import { scrollOnTop } from '../../../../utils/helpers';
import { DragDropIcon } from '../../../profile/engineer/DragDrop/DragDrop';

function RecruitmentDialog(props) {
  const his = useHistory();
  const { open, setOpen } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog className='dialog-wrapper' onClose={handleClose} open={open}>
      <DialogTitle className='dialog-title'>
        <img src={CheckIcon} alt="check-icon" />
      </DialogTitle>
      <DialogContent className='dialog-content'>
        <h3>Thank You!</h3>
        <p>Thank you for reaching out to Gig Engineer. Your request has been successfully submitted. Our team will review your job details and get in touch with you shortly.</p>
        <div className='cta-wrapper'>
          <button onClick={() => { his.push('/gig/new') }} className='btn btn-secondary'>Post a new Job</button>
          <button onClick={() => { his.push('/gig/dashboard') }} className='btn btn-primary'>Dashboard</button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

const Step = (props) => {

  const his = useHistory();
  const deliverablesAndLocation = _get(
    props,
    'newGig.deliverablesAndLocation',
    props.defaultObject.deliverablesAndLocation,
  );
  const isRemote = _get(props, 'newGig.deliverablesAndLocation.isRemote', 'true');

  const [isRemoteState, setIsRemoteState] = useState(false);
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [file, setFile] = useState('');
  const [fileName, setFileName] = useState('');
  const [optedCountry, setOptedCountry] = useState('');
  const [optedProvince, setOptedProvince] = useState('');
  const [optedCity, setOptedCity] = useState('');
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);

  useEffect(() => {
    scrollOnTop();
    props.changeHeadTitle("What skills does your work require?");
    getUniversityList();
    const remoteValue = isRemote === 'true' ? true : false;
    setIsRemoteState(remoteValue);
  }, [props]);

  const [loading, setLoading] = useState(false);
  const handelChange = async (filename, e) => {
    const checkedSize = 1024 * 1024 * 5;
    const selectedFileSize = filename && filename.size;
    if (selectedFileSize > checkedSize) {
      setFileName("");
      toast.error('File size should be less than 5 MB');
      return;
    }

    setFileName(filename.name);
    setLoading(true);
    let img = await getUploadApi(filename);
    setLoading(false);
    setFile(img?.data?.fileUrl);
  }

  const [universityList, setUniversityList] = useState([]);

  const getUniversityList = async () => {
    let university = await getUniversityApi();
    if (university?.data && university?.data.length > 0) {
      setUniversityList(university.data);
    }
  };

  const getCountriesList = async () => {
    let countries = await getCountryStateCity();
    if (countries?.data && countries?.data.length > 0) {
      setCountries(countries.data);
    }
  };

  const getCountryValue = (val) => {
    if (val) setOptedCountry(val);
  };

  const getProvinceValue = (val) => {
    if (val) setOptedProvince(val);
  };

  const getCityValue = (val) => {
    if (val) setOptedCity(val);
  };

  useEffect(() => {
    getCountriesList();
    setOptedCountry(props?.newGig?.deliverablesAndLocation?.location?.country ? props?.newGig?.deliverablesAndLocation?.location?.country : '');
    setOptedProvince(props?.newGig?.deliverablesAndLocation?.location?.state);
    setOptedCity(props?.newGig?.deliverablesAndLocation?.location?.city);
  }, [props]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300
      }
    }
  };

  const gigPost = ({
    values,
    validateForm,
  }) => {
    validateForm().then((errors) => {
      if (_isEmpty(errors)) {
        const obj = {
          ...props.newGig,
          deliverablesAndLocation: {
            ...values
          },
          isRemote: values.isRemote,
          status: 'ACTIVE'
        };
        obj.milestones = JSON.parse(JSON.stringify(obj.deliverablesAndLocation.milestones))
        if (!!obj?.deliverablesAndLocation?.milestones) {
          delete obj.deliverablesAndLocation.milestones
          delete obj.deliverablesAndLocation.deliverables
        }
        saveNewGigApi(obj, props.setNewGigError, props.setNewGigSuccess).then((e) => {
          if (e.status === 200) {
            if ((props?.newGig?.titleAndSkills?.jobType == "recruitment")) {
              setOpen(true)
            }
            else {
              const gigId = e?.data?.gig?._id;
              if (gigId) {
                // his.push(`/gig/edit/${e?.data?.gig?._id}`);
                his.push(`/gig/active/${gigId}`);
              } else {
                his.push('/gig/active');
              }
            }
          }
          else {
            // toast.error(e.message);
          }
        }).catch((err) => {
          console.error(err)
        });
      }
    });
  }

  const saveToDraft = ({
    values,
    validateForm,
    setTouched,
    newGig,
    setNewGigError,
    setNewGigSuccess
  }) => {
    validateForm().then((errors) => {
      if (_isEmpty(errors)) {
        // verify if errors object is equals to '{}' an empty object
        const obj = {
          ...newGig,
          deliverablesAndLocation: {
            ...values
          },
          isRemote: values.isRemote,
          status: 'DRAFT'
        };
        obj.milestones = JSON.parse(JSON.stringify(obj.deliverablesAndLocation.milestones))
        if (!!obj?.deliverablesAndLocation?.milestones) {
          delete obj.deliverablesAndLocation.milestones
          delete obj.deliverablesAndLocation.deliverables
        }
        saveNewDraftGigApi(obj, setNewGigError, setNewGigSuccess);
      } else {
        return setTouched(errors);
      }
    });
  };

  const activeBackBtn = () => {
    props.methodBackClick(2);
  }

  return (

    // <div className='main-container-step3'>
    <>
      <PaddedBox style={{ paddingLeft: `${!!props.auth && !!props.auth.isDrawerOpen && props.auth.isDrawerOpen == true ? "0px" : "0px"}`, marginTop: '-5px' }} >
        <div className='step2-main-heading'>
          <div className='step2-heading-body'>3/3 </div>
          {(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? 'Deliverables & Location' : 'Location & Attachments'}
        </div>

        <div className='step2-body'>
          <div className='step2-img-container'>
            <div className='scope-text'>{(props?.newGig?.titleAndSkills?.jobType != "recruitment") ? 'What skills does your work require?' : 'Please define the job location'}</div>
            <Typography sx={{ fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", color: "#7E84A3" }} >You can save changes at each step and continue from where you left off.</Typography>
            <div className='img-step2-profile'>
              <img src="https://gigengineer-production.s3.af-south-1.amazonaws.com/Assets/Post+a+Gig+Step+3.svg" alt="profile" width="350px" height="350px" />
            </div>
          </div>
          <div className='Formrik-Container-step2'>
            <Formik
              initialValues={{
                ...deliverablesAndLocation,
                isRemote,
                milestones: (!!props.newGig?.milestones && props.newGig?.milestones?.length > 0) ? props.newGig?.milestones : [
                  {
                    "title": "",
                    "description": "",
                    "deadline": "",
                    "amount": 0,
                    "deliverables": []
                  }
                ]
              }}
              enableReinitialize={true}
              validationSchema={newGigProjectDeliverablesSchema}
              onSubmit={(values) => {
                const remoteCondition = values.isRemote === 'true' ? true : false;
                if (!remoteCondition) {
                  if (!values.location.country) {
                    setCountryError(true);
                    setStateError(true);
                    setCityError(true);
                    return;
                  } else if (!values.location.state) {
                    setStateError(true);
                    setCityError(true);
                    return;
                  } else if (!values.location.city) {
                    setCityError(true);
                    return;
                  }
                }

                const obj = {
                  ...props.newGig,
                  deliverablesAndLocation: {
                    ...values
                  },
                  isRemote: values.isRemote,
                  status: 'ACTIVE'
                };
                saveNewGigApi(obj, props.setNewGigError, props.setNewGigSuccess);
                his.push('/gig/active');
              }}
            >
              {(formProps) => {
                const { values, touched, errors, handleChange, setFieldValue } = formProps;
                return (
                  <Form noValidate autoComplete="off">
                    <div className='step2-currency'>
                      <div className='remote-work-step3'>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          error={_get(touched, 'isRemote') && Boolean(_get(errors, 'isRemote'))}
                          required
                        >
                          <InputLabel>Remote work</InputLabel>
                          <Select
                            value={_get(values, 'isRemote')}
                            label="Remote work"
                            onChange={(event) => {
                              setFieldValue('isRemote', event.target.value);
                              if (event.target.value === 'true') {
                                setIsRemoteState(true);
                                setFieldValue('location.country', "");
                                setFieldValue('location.state', "");
                                setFieldValue('location.city', "");
                              } else {
                                setIsRemoteState(false);
                              }
                            }}
                            MenuProps={MenuProps}
                            InputLabelProps={{ shrink: true }}
                            error={touched.isRemote && Boolean(errors.isRemote)}
                            helperText={touched.isRemote && errors.isRemote}
                          >
                            <MenuItem value={'true'}>{`Yes`}</MenuItem>
                            <MenuItem value={'false'}>{`No`}</MenuItem>
                            <MenuItem value={'Hybrid'}>{`Hybrid`}</MenuItem>
                          </Select>
                        </FormControl>
                        <div title="Indicate whether this will be remote or site based work." enterTouchDelay={0}>
                          <HelpIcon sx={{ color: blue[500], fontSize: 16 }} />
                        </div>
                      </div>
                    </div>
                    <div className='step2-currency'>
                      <FormControl variant="outlined" sx={{ width: '100%' }} error={_get(touched, 'location.country') && Boolean(_get(errors, 'location.country'))} disabled={isRemoteState} required={!isRemoteState} >
                        <InputLabel>Country</InputLabel>
                        <Select
                          value={_get(values, 'location.country')}
                          onChange={(e) => {
                            setFieldValue('location.country', e.target.value);
                            getCountryValue(e.target.value);
                            setCountryError(false);
                          }}
                          required="required"
                          label="Country"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                          error={countryError}
                        >
                          {countries.length > 0 &&
                            countries.map(({ name }) => {
                              return (
                                <MenuItem value={name} key={name}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>
                    <div className='step2-currency'>
                      <FormControl variant="outlined" sx={{ width: '100%' }} error={_get(touched, 'location.state') && Boolean(_get(errors, 'location.state'))} disabled={isRemoteState} required={!isRemoteState} >
                        <InputLabel>State</InputLabel>
                        <Select
                          value={_get(values, 'location.state')}
                          onChange={(e) => {
                            setFieldValue('location.state', e.target.value);
                            getProvinceValue(e.target.value);
                            setStateError(false);
                          }}
                          label="Province"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                          error={stateError}
                          required="required"
                        >
                          {countries.length > 0 &&
                            countries.find((x) => x.name === _get(values, 'location.country'))?.states.map(({ name }) => {
                              return (
                                <MenuItem value={name} key={name}>
                                  {name}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </div>

                    <div className='step2-currency'>
                      <FormControl variant="outlined" sx={{ width: '100%' }} error={_get(touched, 'location.city') && Boolean(_get(errors, 'location.city'))} disabled={isRemoteState} required={!isRemoteState} >
                        <InputLabel>City</InputLabel>
                        <Select
                          value={_get(values, 'location.city')}
                          onChange={(e) => {
                            setFieldValue('location.city', e.target.value);
                            getCityValue(e.target.value);
                            setCityError(false);
                          }}
                          label="City"
                          MenuProps={MenuProps}
                          InputLabelProps={{ shrink: true }}
                          error={cityError}
                          required="required"
                        >
                          {countries.length > 0 &&
                            countries.find((el) => el.name === _get(values, 'location.country'))
                              ?.states.find((el) => el.name === _get(values, 'location.state'))
                              ?.cities.map(({ name }) => {
                                return (
                                  <MenuItem value={name} key={name}>
                                    {name}
                                  </MenuItem>
                                );
                              })}
                        </Select>
                      </FormControl>
                    </div>
                    {
                      ((props?.newGig?.titleAndSkills?.jobType != "recruitment"))
                      &&
                      <div className='step2-currency'>
                        {values.milestones.map((milestone, milestoneIndex) => (
                          <Accordion
                            key={`milestones[${milestoneIndex}]`}
                            className="milestone-accordion"
                          >
                            <AccordionSummary style={{ margin: 0, background: '#F5F5F5' }} expandIcon={<ExpandMoreIcon />}>
                              <h4>Milestone {milestoneIndex + 1}</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                              <b>{milestone?.title}</b>
                              <p style={{ marginTop: 0, color: '#1B1F26B8' }}>
                                {milestone?.description}
                                <br />
                                <b>Completion Date:</b> {!!(milestone?.deadline) ? `${(new Date(milestone?.deadline)).toLocaleDateString()}` : "-"}
                              </p>
                              <FieldArray name={`milestones[${milestoneIndex}].deliverables`}>
                                {({ push: pushDeliverable, remove: removeDeliverable }) => (
                                  <div>
                                    {milestone?.deliverables?.map(
                                      (deliverable, deliverableIndex) => (
                                        <Grid
                                          container
                                          spacing={2}
                                          className='milestone-grid-wrapper'
                                          key={`milestones[${milestoneIndex}].deliverables[${deliverableIndex}]`}
                                        >
                                          <div className='deliverable-heading-wrapper'>
                                            <p>Deliverable {deliverableIndex + 1}</p>
                                            <button onClick={() => {
                                              removeDeliverable(deliverableIndex)
                                            }}>
                                              <img src={DeleteImg} />
                                            </button>
                                          </div>
                                          <div className='deliverable-fields-wrapper'>
                                            <TextField
                                              name={`milestones[${milestoneIndex}].deliverables[${deliverableIndex}].deliverableTitle`}
                                              label="Deliverable Title"
                                              value={deliverable.deliverableTitle}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `milestones[${milestoneIndex}].deliverables[${deliverableIndex}].deliverableTitle`,
                                                  e.target.value
                                                )
                                              }
                                              fullWidth
                                            />
                                            <TextField
                                              name={`milestones[${milestoneIndex}].deliverables[${deliverableIndex}].deliverableDesc`}
                                              label="Deliverable Description"
                                              value={deliverable.deliverableDesc}
                                              onChange={(e) =>
                                                setFieldValue(
                                                  `milestones[${milestoneIndex}].deliverables[${deliverableIndex}].deliverableDesc`,
                                                  e.target.value
                                                )
                                              }
                                              multiline
                                              rows={2}
                                              fullWidth
                                            />
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                              <DatePicker
                                                label="Expected Completion Date"
                                                openTo="year"
                                                disablePast
                                                views={['year', 'month', 'day']}
                                                required
                                                name={`milestones[${milestoneIndex}].deliverables[${deliverableIndex}].expectedEndDate`}
                                                value={
                                                  values.milestones[milestoneIndex].deliverables[deliverableIndex]
                                                    .expectedEndDate
                                                }
                                                onChange={(newDate) =>
                                                  setFieldValue(
                                                    `milestones[${milestoneIndex}].deliverables[${deliverableIndex}].expectedEndDate`,
                                                    newDate ? newDate.toISOString() : "" // Convert date to ISO string
                                                  )
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    fullWidth
                                                    error={false}
                                                    helperText={false}
                                                  />
                                                )}
                                              />
                                            </LocalizationProvider>
                                          </div>
                                        </Grid>
                                      )
                                    )}
                                    {
                                      (props?.newGig?.titleAndSkills?.jobType != "fractional")
                                      &&
                                      <Box mt={2}>
                                        <Button
                                          variant="contained"
                                          className="section-deliverable-Button-Step3"
                                          sx={{ border: '1px solid #f99600', background: 'transparent', boxShadow: 'none', marginLeft: 0 }}
                                          onClick={() =>
                                            pushDeliverable({
                                              deliverableTitle: "",
                                              deliverableDesc: "",
                                            })
                                          }
                                        >
                                          + Add Deliverable
                                        </Button>
                                      </Box>
                                    }
                                  </div>
                                )}
                              </FieldArray>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                    }
                    <div className='step2-currency'>

                      <Grid container spacing={1} className="text">
                        <div className='specialrequirements-step3'>
                          <Box sx={{ marginLeft: "12px", marginTop: '30px' }}>
                            <TextField
                              fullWidth
                              value={_get(values, 'specialrequirements.details')}
                              onChange={(event) => {
                                setFieldValue('specialrequirements.details', event.target.value)
                              }}
                              label="Special terms & conditions"
                              variant="outlined"
                              multiline
                              rows={4}
                              required="required"
                            />
                          </Box>
                        </div>

                        <div className='data-upload-step3' >
                          <Box
                            sx={{
                              borderColor: '#0140aa',
                              bgcolor: 'background.paper',
                              border: '1.6px dashed rgb(1, 64, 170)',
                              width: '9rem',
                              height: '2.5rem',
                              color: '#0140aa',
                              borderRadius: '4px',
                              fontWeight: 600,
                              marginTop: '30px',
                              display: 'flex',
                              alignItems: 'center',
                              paddingBottom: '3px',
                              paddingLeft: '5px',
                            }}
                          >
                            <React.Fragment>
                              {loading ? <CircularProgress className="upload-Loading"></CircularProgress> : props.icon ? props.icon :
                                <div title="Max Size 5MB" className='drag-drop-upload-data'>
                                  <DragDropIcon onFileChange={(e) => handelChange(e, { ...formProps })} icons={<img src={RaiseQueryIcon} alt="RaiseQueryIcon" />} text="Attachment"></DragDropIcon>
                                </div>
                              }
                            </React.Fragment>
                          </Box>
                          <div className='textoverflow-step-3' title={fileName}>
                            <span>{fileName ? fileName : ""}</span>
                          </div>
                        </div>

                      </Grid>
                    </div>

                    <ButtonContainerStep3>
                      <div>
                        <Button
                          variant="outlined"
                          type="button"
                          className="step3-back-btn"
                          sx={{ width: '100%', height: "45px" }}
                          onClick={activeBackBtn}
                        >
                          Back
                        </Button>
                      </div>

                      {!props.newGigSaveSucess && (
                        <DraftBtnStep3
                          onClick={(e) => {
                            e.preventDefault();
                            saveToDraft({ ...formProps, ...props });
                          }}
                        >
                          Save as Draft
                        </DraftBtnStep3>
                      )}
                      <div>
                        <GigBtnStep3
                          variant="contained"
                          className={`primary-Btn-post-gig ${props.newGigSaveSucess && 'success'}`}
                          type="submit"
                          onClick={(e) => {
                            e.preventDefault();
                            gigPost({ ...formProps, ...props });
                          }}

                        >
                          {props.newGigSaveSucess ? 'GIG POSTED' : 'POST GIG'}
                        </GigBtnStep3>
                      </div>
                    </ButtonContainerStep3>

                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
        <RecruitmentDialog open={open} setOpen={setOpen} />
      </PaddedBox>
    </>
    // {/* </div> */}
  );
};

const mapStateToProps = (state) => {
  return {
    newGig: state.newGig,
    error: state.newGig.error,
    saveSuccess: state.newGig.saveSuccess,
    newGigSaveSucess: state.newGig.newGigSaveSucess
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setNewGigSuccess: (details) => dispatch(setNewGigSuccess(details)),
    setNewGigError: (details) => dispatch(setNewGigError(details))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step);


